import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../../systemUtils/slaDefinition/SlaDefinitionActions';
import OrgActions from '../../../../systemUtils/organisation/OrganisationActions';
import { useNavigate, useParams } from 'react-router-dom';
import UserCore from '../../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import { UserClaimsContext } from '../../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import ButtonBox from '../../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import { getHeadersAsync } from '../../../../systemUtils/common/CommonHelpers';
import CommonPageContext from '../../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import {
  ControlState,
  KeyValuePair,
} from '../../../../sysObjects/common.types';
import ListTable from '../../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ListTableRow from '../../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import SLADefinitionIndexTypes from './Definition.types';
import serviceActions from '../../../../systemUtils/services/ServiceDefinitionActions';
import './SlaDefinitionIndex.css';
const SLA_Definition_Index: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userSettings } = useUserSettingsContext();
  const context = React.useContext(CommonPageContext);

  const { orgId, billingId } = useParams();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [serviceDefinitions, setServiceDefinitions] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [list, setList] = React.useState<SLADefinitionIndexTypes.TableRow[]>(
    [],
  );

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const findCaseAsync = async () => {
    const headers = await getHeadersAsync(userClaims, instance);
    const orgResult = await OrgActions.getOrgByIDAsync(orgId!, headers);

    if (orgResult.isFailure || !orgResult.result) {
      showMessage(
        locales.ApiResponses.loadingFailed,
        'negative',
        '/organisations',
      );
      return;
    }
    await serviceActions
      .fetchServiceDefinitions(headers, intl.locale)
      .then((rst) => {
        if (rst.isFailure) {
          showMessage(
            locales.ApiResponses.loadingFailed,
            'negative',
            `/Organisations/${orgId}/billing`,
          );
          return;
        }
        setServiceDefinitions(rst.result!);
      });

    await actions
      .findAsync(headers, {
        orgId: orgId!,
      })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          showMessage(
            locales.ApiResponses.loadingFailed,
            'negative',
            `/organisations/${orgId}/billing/${billingId}`,
          );
          return;
        }

        const breadcrumbs = [
          ...locales.breadcrumbs.base,
          {
            label: orgResult.result!.name,
            link: `/organisations/${orgId}/edit`,
            key: orgId,
          },
          {
            label: locales.breadcrumbs.index.label,
            key: 'sla',
          },
        ];
        context?.handleCrumbUpdate(breadcrumbs);

        setList(
          result.result.map((li) => {
            return {
              ...li,
              isExpanded: false,
              isButtonRowExpanded: false,
            };
          }),
        );
      });
  };

  React.useEffect(() => {
    if (!orgId || !billingId) {
      showMessage(
        locales.ApiResponses.loadingFailed,
        'negative',
        `${orgId}/billing/`,
      );
      return;
    }
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }

    findCaseAsync();
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={isLoading}
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableName,
          emptyMessage: locales.emptyMessage,
        }}
        rows={list.map((li) => (
          <ListTableRow
            id={li.id}
            events={{
              OnExpandChange: (isExpanded: boolean) => {
                setList((prevItems) =>
                  prevItems.map((item) =>
                    item.id === li.id
                      ? {
                          ...item,
                          isExpanded,
                          isButtonRowExpanded:
                            isExpanded === true
                              ? false
                              : item.isButtonRowExpanded,
                        }
                      : {
                          ...item,
                          isExpanded: false,
                          isButtonRowExpanded: false,
                        },
                  ),
                );
              },
              OnButtonVisibilityChange: (isButtonRowExpanded: boolean) => {
                setList((prevItems) =>
                  prevItems.map((item) =>
                    item.id === li.id
                      ? { ...item, isButtonRowExpanded }
                      : {
                          ...item,
                          isExpanded: false,
                          isButtonRowExpanded: false,
                        },
                  ),
                );
              },
            }}
            isButtonRowExpanded={li.isButtonRowExpanded}
            isExpanded={li.isExpanded}
          >
            <ListTableRow.Collapsed>
              <div>{li.name}</div>
              <div>
                {!li.serviceDefinitionId
                  ? ''
                  : serviceDefinitions.find(
                      (sd) => sd.key === li.serviceDefinitionId,
                    )?.value}
              </div>
            </ListTableRow.Collapsed>
            <ListTableRow.Expanded>
              <>
                <div>{locales.name}</div>
                <div>{li.name}</div>
                <div>{locales.description}</div>
                <div>{li.description}</div>
                <div>{locales.relatedService}</div>
                <div>
                  {!li.serviceDefinitionId
                    ? ''
                    : serviceDefinitions.find(
                        (sd) => sd.key === li.serviceDefinitionId,
                      )?.value}
                </div>
                <div>{locales.duration}</div>
                <div>{li.duration?.split('.')[0]}</div>
                <div>{locales.actions}</div>
                <div>
                  <ButtonBox
                    id={`sla-edit-${li.id}`}
                    key={`sla-edit-${li.id}`}
                    displayBorder={true}
                    className="Button-Box-expanded"
                    buttons={[
                      {
                        id: `edit-${li.id}`,
                        controlState: 'positive',
                        label: locales.editButton,
                        onClick: () =>
                          navigate(
                            `/organisations/${orgId}/billing/${billingId}/sla/edit/${li.id}`,
                          ),
                      },
                    ]}
                  />
                </div>
              </>
            </ListTableRow.Expanded>
            <ListTableRow.ButtonRow
              actionLabel={locales.actions}
              isExpanded={li.isButtonRowExpanded}
            >
              <ButtonBox
                id={`sla-edit-${li.id}`}
                key={`sla-edit-${li.id}`}
                displayBorder={true}
                buttons={[
                  {
                    id: `edit-${li.id}`,
                    controlState: 'positive',
                    label: locales.editButton,
                    onClick: () =>
                      navigate(
                        `/organisations/${orgId}/billing/${billingId}/sla/edit/${li.id}`,
                      ),
                  },
                ]}
              />
            </ListTableRow.ButtonRow>
          </ListTableRow>
        ))}
        className="SLA-Definition-Index-Table"
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular" />
          <div className="Heading Text-Regular">{locales.colNames[0]}</div>
          <div className="Heading Text-Regular">{locales.colNames[1]}</div>
          <div className="Heading Text-Regular" />
        </ListTable.Headers>
      </ListTable>
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'slaCreate',
            label: locales.createButton,
            mode: 'positive',
            clickEvent: () => {
              navigate(
                `/organisations/${orgId}/billing/${billingId}/sla/create`,
              );
            },
          }}
        >
          {locales.createSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default SLA_Definition_Index;
