import React, { useContext, useEffect } from 'react';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';
import RecommendationsTabTypes from './RecommendationsTab.types';
import RecommendationRowTypes from '../../recommendations/recommendationRow/RecommendationRow.types';
import AddRecommendation from '../../recommendations/addRecommendation/AddRecommendation';
import RecommendationRow from '../../recommendations/recommendationRow/RecommendationRow';
import './RecommendationsTab.css';
import { FunctionalResult } from '../../../../../sysObjects/FunctionalResult';
import ServiceRecommendation_Types from '../../../../../sysObjects/apiModels/ServiceRecommendation.Types';
import { KeyValuePair } from '../../../../../sysObjects/common.types';
import ServiceDefinitionActions from '../../../../../systemUtils/services/ServiceDefinitionActions';
import { getHeadersAsync, getServerErrors } from '../../../../../systemUtils/common/CommonHelpers';
import { UserClaimsContext } from '../../../../sharedControls/contexts/UserClaimsContext';
import { useMsal } from '@azure/msal-react';
import { RecommendationUtils } from '../../../../../systemUtils/recommendations/recommendationUtils';
import { useIntl } from 'react-intl';

const RecommendationsTab: React.FC<RecommendationsTabTypes.Props> = (props) => {
  const [rows, setRows] = React.useState<RecommendationRowTypes.RecommendationRowItem[]>(
    [],
  );
  const { userClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();
  const intl = useIntl();

  const [serviceDefinitions, setServiceDefinitions] = React.useState<KeyValuePair<string>[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const loadDataAsync = async () => {
      await ServiceDefinitionActions.fetchServiceDefinitions(
        await getHeadersAsync(userClaims, instance),
        intl.locale,
      ).then((result) => {
        if (result.isFailure) {
          props.events.onError(props.labels.addRecommendationsErrors.loadingFailed);
          return;
        }

        setServiceDefinitions(result.result!);
      });
    };

    setIsLoading(true);

    const validRows = props.rows.filter((row) =>
      serviceDefinitions.some((sd) => sd.key === row.serviceDefinitionId)
    );

    if (validRows.length !== props.rows.length) {
      loadDataAsync().then(() => {
        setRows(props.rows);
        setIsLoading(false);
      });

      return;
    }

    setRows(props.rows);
    setIsLoading(false);
  }, [props.rows]);

  const handleDeleteRecommendation = async (recommendationId: string): Promise<FunctionalResult<void>> => {
    setIsLoading(true);
    const result = await RecommendationUtils.deleteServiceRecommendationAsync(recommendationId, props.caseId, await getHeadersAsync(userClaims, instance));
    if (result.isFailure) {
      props.events.onError(props.labels.deleteRecommendationsErrors.deleteFailed);
      return result;
    }
    setIsLoading(false);

    props.events.triggerRefresh();
    return result;
  };

  const handleCreateRecommendation = async (recommendation: ServiceRecommendation_Types.ServiceRecommendation): Promise<FunctionalResult<string>> => {
    setIsLoading(true);
    const result = await RecommendationUtils.addServiceRecommendationAsync(recommendation, await getHeadersAsync(userClaims, instance));
    if (result.isFailure) {
      props.events.onError(getServerErrors({ ApiResponses: props.labels.addRecommendationsErrors }, result.errorCode));
      return result;
    }
    setIsLoading(false);

    props.events.triggerRefresh();
    return result;
  }

  return (
    <>
      <ListTable
        isLoading={isLoading || props.isCaseLoading}
        className="Recommendation-Table"
        labels={{
          tableName: props.labels.table.tableName,
          of: props.labels.table.of,
          size: props.labels.table.size,
          emptyMessage: props.labels.table.emptyMessage,
        }}
        rows={
          rows?.map((row, rI) => {
            return <RecommendationRow
              key={`Recommendations_Rows_${rI}`}
              item={row}
              events={{
                onDeleteRecommendation: handleDeleteRecommendation,
              }}
              labels={props.labels.recommendationRow}
            />;
          }) || []
        }
        pagingDetails={props.pagingDetails}
        defaultItem={!props.readOnly &&
          (<AddRecommendation
            events={{
              addRecommendation: handleCreateRecommendation,
            }}
            serviceDefinitions={serviceDefinitions ?? []}
            caseId={props.caseId}
            labels={props.labels.addRecommendation}
          />)
        }
      >
        <ListTable.Headers>
          {[
            <div key="StatusCol"></div>,
            ...props.labels.table.tableHeaders.map((header: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, hI: any) => {
              return <div key={`RecommendationItem_Header_${hI}`}>{header}</div>;
            }),
          ]}
        </ListTable.Headers>
      </ListTable>
    </>
  );
};

export default RecommendationsTab;
