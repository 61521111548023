import React from 'react';
import { PricingDefinitionItemTypes } from './PricingItemRow.types';
import { getLocalDateString } from '../../../../systemUtils/common/DateHelpers';
import { useIntl } from 'react-intl';
import { getCurrencyString } from '../../../../systemUtils/common/CommonHelpers';
import './PricingItemRow.css';
import { FormControlRef } from '../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';
import FormTextCapture from '../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import StatusLabel from '../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import Button from '../../../sharedControls/general/button/Button';
import Expander from '../../../sharedControls/general/expander/Expander';

const PricingItemRow: React.FC<PricingDefinitionItemTypes.Props> = (props) => {
  const priceRef = React.useRef<FormControlRef>(null);
  const serviceRef = React.useRef<FormControlRef>(null);
  const [price, setPrice] = React.useState(props.item.price.toString() ?? '');
  const [isExpanded, setExpanded] = React.useState(
    props.item.expanded
  );
  const intl = useIntl();

  const boundPrice = (newPrice: string) => {
    if (newPrice === '') {
      setPrice(newPrice);
      return;
    }

    const priceAsFloat = parseFloat(newPrice);
    if (isNaN(priceAsFloat) || priceAsFloat <= 0) {
      setPrice('')
      return;
    }

    setPrice(newPrice);
  }

  const renderExpandedRow = () => {
    return (
      <div className='Expanded-row'>

        <FormTextCapture
          textInputType={'number'}
          fieldId={`unitPrice_${props.item.selectedServiceId}`}
          id={`unitPrice_${props.item.selectedServiceId}`}
          label={props.labels.price}
          value={price}
          ref={priceRef}
          requiredDetails=
          {{
            formLabel: props.labels.required,
            message: props.labels.priceRequiredError
          }}
          displayMode={'Column'}
          onChange={(e) => boundPrice(e.value as string)}
        />

        <div className="col Text-Regular">{props.labels.currency}</div>
        <div className="col Text-Regular Value-Column">
          <StatusLabel
            label={props.item.currency}
            renderAs="span"
            key={`currency_${props.item.selectedServiceId}`}
            status="neutral"
          />
        </div>

        <div className="col Text-Regular">{props.labels.deliveryFormat}</div>
        <div className="col Text-Regular Value-Column">
          <StatusLabel
            label={props.item.deliveryFormat}
            renderAs="span"
            key={`delivery_format_${props.item.selectedServiceId}`}
            status="neutral"
          />
        </div>

        <div className="col Text-Regular">{props.labels.relatedCondition}</div>
        <div className="col Text-Regular Value-Column">{props.item.relatedCondition}</div>

        <div className="col Text-Regular">{props.labels.code}</div>
        <div className="col Text-Regular Value-Column">{props.item.code}</div>

        <div>{props.labels.actions}</div>
        <div className="Colspan">
          <div className="Button-Box">
            <Button
              mode="positive"
              itemKey={`Button_Save${props.item.selectedServiceId}}`}
              label={props.labels.save}
              clickEvent={() => {
                if (props.item.onSave) {
                  if (
                    priceRef.current !== null &&
                    serviceRef.current !== null
                  ) {
                    if (
                      !priceRef.current.triggerValidation() ||
                      !serviceRef.current.triggerValidation()
                    ) {
                      return;
                    }
                  }

                  const priceAsFloat = parseFloat(price);

                  if (isNaN(priceAsFloat) || props.item.price === priceAsFloat || priceAsFloat <= 0) {
                    return;
                  }

                  props.item.onSave(priceAsFloat, props.item.selectedServiceId);
                  setExpanded(false);
                }
              }}
            />
            <Button
              mode="positive"
              itemKey={`Button_Cancel_${props.item.selectedServiceId}}`}
              label={props.labels.cancel}
              clickEvent={() => {
                setPrice(props.item.price.toString() ?? '');
                setExpanded(false);
              }}
              useCooldown={false}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderExpander = () => {
    return (
      <div>
        <Expander
        id={`${props.item.selectedServiceId}_Status`}
        key={`${props.item.selectedServiceId}_Status`}
        isExpanded={isExpanded === true} 
        eventHandler={{ onClick: () => setExpanded(!isExpanded) }}
        />
      </div>
    );
  };

  const renderCollapsedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.item.name}</div>
        <div className="td">{props.item.price > 0 ? getCurrencyString(props.item.price, props.item.currency) : 'N/A'}</div>
        <div className="td">
          <StatusLabel
            label={props.item.deliveryFormat}
            renderAs="span"
            key={`delivery_format_header_${props.item.selectedServiceId}`}
            status="neutral"
          />
        </div>
        <div className="td">{props.item.relatedCondition}</div>
        <div className="td">{props.item.code}</div>
        <div className="td">{props.item.validFrom ? getLocalDateString(props.item.validFrom, intl, "MM") : 'N/A'}</div>
      </>
    )
  }

  const renderExpandedHeader = () => {
    return (
      <>
        {renderExpander()}
        <div className="td">{props.item.name}</div>
        <div />
        <div />
        <div />
        <div />
        <div className="td">{props.item.validFrom ? getLocalDateString(props.item.validFrom, intl, "MM") : 'N/A'}</div>
      </>
    )
  }

  const renderRow = () => {
    return (
      <>
        {
          isExpanded
            ? renderExpandedHeader()
            : renderCollapsedHeader()
        }
        {isExpanded && renderExpandedRow()}
      </>
    )
  };

  return <div className="Pricing-Item" data-testid={`price-item${props.item.selectedServiceId}`}>{renderRow()}</div>;
};

export default PricingItemRow;