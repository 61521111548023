import RootLayout from './layouts/commonLayout/RootLayout';
import ErrorPage from './errorPage/ErrorPage';

import InternalUsers_Index from './internalUsers/internalUsersIndex/InternalUsersIndex';
import InternalUserCreateUpdate from './internalUsers/internalUsersCreateUpdate/InternalUsersCreateUpdate';

import HomePage from './internalDasboard/Dashboard';
import ExternalUsers from './externalUsers/externalUsersIndex/ExternalUsersIndex';
import CreateExternalUser from './externalUsers/externalUsersCreateUpdate/externalUsersCreateUpdate';
import CustomerProfileCreateUpdate from './externalUsers/customerProfile/ProfileCreateUpdate';

import Consent from './consent/Consent';

import PricingDefinition_Index from './pricingDefinitionDetails/pricingDefinitionDetailsIndex/PricingDefinitionDetailsIndex';
import PricingDefinitionCreate from './pricingDefinitionDetails/pricingDefinitionDetailsCreate/pricingDefinitionCreate';
import pricingDefinitionListEdit from './pricingDefinitionDetails/pricingDefinitionListEdit/pricingDefinitionListEdit';
import SLA_Definition_CU from './organisations/sla/slaDefinitionCreateUpdate/SlaDefinitionCreateUpdate';

import ServiceDefinition_CU from './serviceDefinitionDetails/serviceDefinitionCreateUpdate/ServiceDefinitionCreateUpdate';
import ServiceDefinition_Index from './serviceDefinitionDetails/serviceDefinitionIndex/ServiceDefinition_Index';

import LoadingPage from './loading/Loading';
import LandingPage from './landingPage/LandingPage';

import CaseContainerPage from './cases/caseContainer/CaseContainerPage';
import CaseIndexPage from './cases/index/index';
import CreateReferralPage from './cases/createReferral/Create';

import OrganisationIndex from './organisations/organisationsIndex/OrganisationsIndex';
import Organisation_CU from './organisations/organisationsCreateUpdate/OrganisationCreateUpdate';
import OrganisationalBillingCreateUpdate from './organisations/billing/billingCreateUpdate/BillingCreateUpdate';

import AssessmentAppointmentsDefinition_CU from './assementAppointments/AssessmentAppointmentsDefinitionCreateUpdate';
import UnauthorizedUserPage from './unauthorised/UnauthorisedUserPage';
import SLA_Definition_Index from './organisations/sla/slaDefinitionIndex/SlaDefinitionIndex';


export namespace Pages {
  export const Error = ErrorPage;
  export const Landing = LandingPage;
  export const Loading = LoadingPage;
  export const UnauthorisedUser = UnauthorizedUserPage;

  export const ListInternalUsers = InternalUsers_Index;
  export const EditInternalUser = InternalUserCreateUpdate;

  export const Organisation = OrganisationIndex;
  export const CreateOrg = Organisation_CU;
  export const CreateBilling = OrganisationalBillingCreateUpdate;

  export const SlaDefinition = SLA_Definition_Index;
  export const CreateSlaDefinition = SLA_Definition_CU;

  export const Home = HomePage;
  export const ListExternalUsers = ExternalUsers;
  export const ExternalUser = CreateExternalUser;
  export const CreateUpdateProfile = CustomerProfileCreateUpdate;
  export const ConsentView = Consent;

  export const CreateServiceDefinition = ServiceDefinition_CU;
  export const ListServiceDefinition = ServiceDefinition_Index;

  export const ListPricingDefinition = PricingDefinition_Index;
  export const CreatePricingDefinition = PricingDefinitionCreate;
  export const UpdatePricingList = pricingDefinitionListEdit;

  export const CaseIndex = CaseIndexPage;
  export const CaseContainer = CaseContainerPage;

  export const CreateReferral = CreateReferralPage;

  export const AA_CU = AssessmentAppointmentsDefinition_CU;
}

export namespace Layouts {
  export const BaseLayout = RootLayout;
}
