import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/services/ServiceDefinitionActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import Enumerations, {
  getHeadersAsync,
} from '../../../systemUtils/common/CommonHelpers';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ServiceDefinitionIndexTypes from './ServiceDefinition_Index.types';
import ListTableRow from '../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import './ServiceDefinition_Index.css';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';

const ServiceDefinition_index: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);
  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [services, setServices] = React.useState<
    ServiceDefinitionIndexTypes.RowData[]
  >([]);

  const [conditions, setConditions] = React.useState<KeyValuePair<number>[]>(
    [],
  );
  const [deliveryFormats, setDeliveryFormats] = React.useState<
    KeyValuePair<number>[]
  >([]);

  const { instance } = useMsal();

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    actions
      .findAsync({ active: 0 }, await getHeadersAsync(userClaims, instance))
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
          return;
        }

        context?.handleCrumbUpdate(locales.breadcrumbs);
        setServices(
          result.result.map((service) => {
            return {
              ...service,
              isExpanded: false,
              isButtonRowExpanded: false,
            };
          }),
        );
      });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }
    setConditions(Enumerations.getRelatedConditions(intl.locale));
    setDeliveryFormats(Enumerations.getDeliveryFormats(intl.locale));

    loadDataAsync();
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={isLoading}
        labels={{
          tableName: locales.tableHeading,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
        key="fk"
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        rows={services.map((service) => {

          const buttons: ButtonBoxTypes.Button[] = [

            {
              controlState: 'positive',
              label: locales.editButton,
              id: `edit-${service.id}`,
              onClick: () => navigate(`/services/edit/${service.id}`),
            },
            service.assessmentAppointmentDefinition !== null
              ? {
                  id: `editAssessmentDefinition-${service.id}`,
                  controlState: 'positive',
                  label: locales.editAssessmentDefinitionButton,
                  onClick: () =>
                    navigate(
                      `/services/${service.id}/appointmentTemplate/edit/${
                        service.assessmentAppointmentDefinition!.id
                      }`,
                    ),
                }
              : {
                  id: `createAssessmentDefinition-${service.id}`,
                  controlState: 'positive',
                  label: locales.createAssessmentDefinitionButton,
                  onClick: () =>
                    navigate(
                      `/services/${service.id}/appointmentTemplate/create`,
                    ),
                },
          ];
          return (
            <ListTableRow
              key={`ListTableRow-${service.id}`}
              id={`ListTableRow-${service.id}`}
              className="Service-List-Item"
              events={{
                OnExpandChange: (isExpanded: boolean) => {
                  setServices((prevItems) =>
                    prevItems.map((item) =>
                      item.id === service.id
                        ? {
                            ...item,
                            isExpanded,
                            isButtonRowExpanded:
                              isExpanded === true
                                ? false
                                : item.isButtonRowExpanded,
                          }
                        : {
                            ...item,
                            isExpanded: false,
                            isButtonRowExpanded: false,
                          },
                    ),
                  );
                },
                OnButtonVisibilityChange: (isButtonRowExpanded: boolean) => {
                  setServices((prevItems) =>
                    prevItems.map((item) =>
                      item.id === service.id
                        ? { ...item, isButtonRowExpanded }
                        : {
                            ...item,
                            isExpanded: false,
                            isButtonRowExpanded: false,
                          },
                    ),
                  );
                },
              }}
              isButtonRowExpanded={service.isButtonRowExpanded}
              isExpanded={service.isExpanded}
            >
              <ListTableRow.Collapsed>
                <div>{`${service.externalDisplayName} - ${conditions.find((rc) => rc.key === service.relatedCondition)?.value || ''} - ${deliveryFormats.find((df) => df.key === service.deliveryFormat)?.value || ''}`}</div>
              </ListTableRow.Collapsed>
              <ListTableRow.Expanded>
                <>
                  <div>{locales.code}</div>
                  <div>{service.serviceCode}</div>
                  <div>{locales.type}</div>
                  <div>{service.serviceType}</div>
                  <div>{locales.nomCode}</div>
                  <div>{service.nominalCode}</div>
                  <div>{locales.description}</div>
                  <div>{service.description}</div>
                  <div>{locales.action}</div>
                  <ButtonBox
                    className="Button-Box-Container-Expanded"
                    id={`ButtonBox-${service.id}`}
                    displayBorder={true}
                    buttons={buttons}
                  ></ButtonBox>
                </>
              </ListTableRow.Expanded>
              <ListTableRow.ButtonRow
                actionLabel={locales.action}
                isExpanded={service.isButtonRowExpanded}
              >
                <ButtonBox
                  className="Button-Box-Container"
                  id={`ButtonBox-${service.id}-ActionRow`}
                  displayBorder={false}
                  buttons={buttons}
                ></ButtonBox>
              </ListTableRow.ButtonRow>
            </ListTableRow>
          );
        })}
        className="Service-Definition-Index"
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular" />
          <div className="Heading Text-Regular">{locales.headers.name}</div>
          <div className="Heading Text-Regular" />
        </ListTable.Headers>
      </ListTable>
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButtonBilling',
            label: locales.createButton,
            mode: 'positive',
            clickEvent: () => navigate('/services/create'),
          }}
        >
          {locales.createSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default ServiceDefinition_index;
