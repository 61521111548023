import React from 'react';
import './CloseBox.css';
import { FaX } from 'react-icons/fa6';
import CloseBoxTypes from './CloseBox.types';

const CloseBox: React.FC<CloseBoxTypes.Props> = (props) => {
  return (
    <div
      className="CloseBox neutral"
      role='button'
      onClick={() => {
        if (props.onClick !== undefined) {
          props.onClick();
        }
      }}
    >
      <FaX className='icon'  />
    </div>
  );
};

export default CloseBox;
