import React from 'react';
import { useIntl } from 'react-intl';
import { OrgActions } from '../../../systemUtils/organisation/OrganisationActions';
import { useNavigate } from 'react-router-dom';

import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import Organisation_Types from '../../../sysObjects/apiModels/Organisation.types';
import { useMsal } from '@azure/msal-react';

import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import Enumerations, {
  getHeadersAsync,
} from '../../../systemUtils/common/CommonHelpers';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ListTableRow from '../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';

import './OrganisationsIndex.css';
const OrganisationIndex: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { userSettings } = useUserSettingsContext();
  const [orgs, setOrgs] = React.useState<
    Organisation_Types.organisationTableRow[]
  >([]);
  const [relatingOrgs, setRelatingOrgs] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [industrySectors, setSectors] = React.useState<KeyValuePair<number>[]>(
    [],
  );

  const showMessages = (
    message: string,
    state: ControlState,
    path: string | null = null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  React.useEffect(() => {
    setSectors(Enumerations.getIndustrySectors(intl.locale));

    const loadDataAsync = async () => {
      await OrgActions.lookUpAsync(
        {},
        await getHeadersAsync(userClaims, instance),
      )
        .then((result) => {
          if (result.isFailure || !result.result) {
            showMessages(locales.ApiResponses.loadingFailed, 'negative');
          } else {
            setRelatingOrgs(
              result.result?.map((org) => {
                return { key: org.id.toString(), value: org.name };
              }) || [],
            );
            setOrgs(
              result.result?.map((org) => {
                return { ...org, isExpanded: false, buttonRowExpanded: false };
              }) || [],
            );
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
          showMessages(locales.ApiResponses.loadingFailed, 'negative', '/');
        });
    };

    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      navigate('/');
      return;
    }

    loadDataAsync();
    context?.handleCrumbUpdate(locales.breadcrumbs);
  }, []);

  const DetermineUserButtons = (
    org: Organisation_Types.organisationTableRow,
  ) => {
    let buttons: ButtonBoxTypes.Button[] = [];
    if (UserCore.userIsSuperUser(userClaims!.user!)) {
      buttons.push({
        controlState: 'positive',
        id: `nav-edit-${org.id}`,
        label: locales.editButton,
        onClick: () => navigate(`/organisations/${org.id}/edit`),
      });

      if (!org.billingDetailsId) {
        buttons.push({
          id: `nav-create-billing-${org.id}`,
          controlState: 'positive',
          label: locales.createBillingDetails,
          onClick: () => navigate(`/organisations/${org.id}/billing/create`),
        });
      } else {
        buttons.push({
          id: `nav-edit-billing-${org.id}`,
          controlState: 'positive',
          label: locales.editBillingDetails,
          onClick: () =>
            navigate(
              `/organisations/${org.id}/billing/edit/${org.billingDetailsId}`,
            ),
        });
        buttons.push({
          id: `nav-sla-${org.id}`,
          controlState: 'positive',
          label: locales.slaButton,
          onClick: () =>
            navigate(
              `/organisations/${org.id}/billing/${org.billingDetailsId}/sla`,
            ),
        });
      }
    }
    return buttons;
  };

  return isLoading ? (
    <PageLoader alt={locales.common.Load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={false}
        labels={{
          tableName: locales.tableName,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        rows={orgs.map((org) => {
          return (
            <ListTableRow
              isButtonRowExpanded={org.buttonRowExpanded}
              isExpanded={org.isExpanded}
              events={{
                OnExpandChange: (isExpanded: boolean) => {
                  setOrgs((prevOrgs) =>
                    prevOrgs.map((prevOrg) =>
                      prevOrg.id === org.id
                        ? {
                            ...prevOrg,
                            isExpanded,
                            buttonRowExpanded:
                              isExpanded === true
                                ? false
                                : prevOrg.buttonRowExpanded,
                          }
                        : {
                            ...prevOrg,
                            isExpanded: false,
                            buttonRowExpanded: false,
                          },
                    ),
                  );
                },
                OnButtonVisibilityChange: (buttonRowExpanded: boolean) => {
                  setOrgs((prevOrgs) =>
                    prevOrgs.map((prevOrg) =>
                      prevOrg.id === org.id
                        ? { ...prevOrg, buttonRowExpanded }
                        : {
                            ...prevOrg,
                            isExpanded: false,
                            buttonRowExpanded: false,
                          },
                    ),
                  );
                },
              }}
              id={`list-table-row-${org.id}`}
              key={`list-table-row-${org.id}`}
            >
              <ListTableRow.ButtonRow
                actionLabel={locales.actions}
                isExpanded={org.buttonRowExpanded}
              >
                <ButtonBox
                  buttons={DetermineUserButtons(org)}
                  id={`Button-Box-${org.id}`}
                  displayBorder={false}
                />
              </ListTableRow.ButtonRow>
              <ListTableRow.Expanded>
                <>
                  <div>{locales.colNames.OrgName}</div>
                  <div>{org.name}</div>
                  <div> {locales.colNames.sectorCode}</div>
                  <div>
                    {
                      industrySectors.find(
                        (s) => s.key.toString() === org.industrySectorCode,
                      )?.value
                    }
                  </div>
                  <div>Actions</div>
                  <ButtonBox
                    buttons={DetermineUserButtons(org)}
                    id={`Button-Box-${org.id}-expanded`}
                    displayBorder={true}
                    className="Button-Box-expanded"
                  />
                </>
              </ListTableRow.Expanded>
              <ListTableRow.Collapsed>
                <div>{org.name}</div>
                <div>
                  {
                    industrySectors.find(
                      (s) => s.key.toString() === org.industrySectorCode,
                    )?.value
                  }
                </div>
              </ListTableRow.Collapsed>
            </ListTableRow>
          );
        })}
        className="Organisational-List-Table"
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular" />
          <div className="Heading Text-Regular">{locales.colNames.OrgName}</div>
          <div className="Heading Text-Regular">
            {locales.colNames.sectorCode}
          </div>
          <div className="Heading Text-Regular" />
        </ListTable.Headers>
      </ListTable>

      {UserCore.userIsSuperUser(userClaims!.user!) ? (
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButton',
            label: locales.infoButton,
            mode: 'positive',
            clickEvent: () => navigate('/organisations/create'),
          }}
        >
          {locales.infoSummary}
        </InformationButton>
      ) : null}
    </div>
  );
};

export default OrganisationIndex;
