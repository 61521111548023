import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import UserCore from '../../../../systemUtils/userUtils/SystemUserActions';
import actions from '../../../../systemUtils/slaDefinition/SlaDefinitionActions';
import serviceActions from '../../../../systemUtils/services/ServiceDefinitionActions';
import types from '../../../../sysObjects/apiModels/SlaDefinition.types';
import Enumerations, {
  getHeadersAsync,
  getServerErrors,
} from '../../../../systemUtils/common/CommonHelpers';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import {
  ControlState,
  KeyValuePair,
  OrderedKeyValuePair,
} from '../../../../sysObjects/common.types';
import PageLoader from '../../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import FormTextCapture from '../../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormTextArea from '../../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import FormDropDown from '../../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import InformationButton from '../../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import FormDuration from '../../../../systemComponents/sharedControls/formControls/formDuration/FormDuration';
import CommonPageContext from '../../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import OrgActions from '../../../../systemUtils/organisation/OrganisationActions';

const Sla_Definition_CU: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { orgId, billingId, id } = useParams();
  const { instance } = useMsal();
  const context = React.useContext(CommonPageContext);

  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [overRideStatusControl, setOverRideStatusControl] = React.useState<
    boolean | undefined
  >();
  const [overRideServiceStatusControl, setOverRideServiceStatusControl] =
    React.useState<boolean | undefined>();
  const [caseStatuses, setCaseStatuses] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [serviceDefinitions, setServiceDefinitions] = React.useState<
    KeyValuePair<string>[]
  >([]);

  const [serviceFulfillmentStatuses, setServiceFulfillmentStatuses] =
    React.useState<OrderedKeyValuePair<number>[]>([]);

  const [requiredStatusDetails, setRequiredStatusDetails] = React.useState<
    string | undefined
  >();
  const [requiredServiceStatusDetails, setRequiredServiceStatusDetails] =
    React.useState<string | undefined>();

  const serviceDeliveries = 3; // "active service deliveries" case status - with this the start/end service state becomes valid to set
  const [startServiceStatusDisabled, setStartServiceStatusDisabled] =
    React.useState<boolean>(true);
  const [endServiceStatusDisabled, setEndServiceStatusDisabled] =
    React.useState<boolean>(true);

  const [pageStates, setPageState] = React.useState<KeyValuePair<string>[]>([
    {
      key: locales.controls.pages[0],
      value: 'neutral',
    },
  ]);

  const [serviceIsSelected, setServiceIsSelected] =
    React.useState<boolean>(false);

  const [formData, setFormData] = React.useState<types.slaDefinitionItem>(
    actions.createDefault(billingId || ''),
  );

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null | undefined
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleServiceChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    const serviceDefinitionId = result.value ? String(result.value) : undefined;

    setServiceIsSelected(serviceDefinitionId !== undefined);

    if (serviceDefinitionId === undefined) {
      setFormData((prevData) => ({
        ...prevData,
        serviceDefinitionId: undefined,
        startServiceStatus: undefined,
        endServiceStatus: undefined,
      }));
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      serviceDefinitionId,
    }));

    const startServiceStatusDisabled =
      formData.startStatus !== serviceDeliveries;
    setStartServiceStatusDisabled(startServiceStatusDisabled);

    const endServiceStatusDisabled = formData.endStatus !== serviceDeliveries;
    setEndServiceStatusDisabled(endServiceStatusDisabled);
    
    if (serviceIsSelected) {
      validateServiceStatuses(formData.startStatus, formData.endStatus, formData.startServiceStatus, formData.endServiceStatus);
    }
  };


  const validateCaseStatuses = (
    startStatus: number | undefined,
    endStatus: number | undefined
  ): string | undefined => {

    if (startStatus !== undefined && endStatus !== undefined) {
        
      if (startStatus! > endStatus!) {
        return locales.controls.startIsLessThanEnd;
      } else if (startStatus! === endStatus!
          && serviceIsSelected
          && endStatus! !== serviceDeliveries) {
            return locales.controls.startIsLessThanEnd;
      } 
    } 

    return undefined;
  };

  const validateServiceStatuses = (
    startStatus: number | undefined,
    endStatus: number | undefined,
    startServiceStatus: number | undefined | null,
    endServiceStatus: number | undefined | null
  ) : string | undefined => {

    const startServiceHasValue = startServiceStatus !== null && startServiceStatus !== undefined;
    const endServiceHasValue = endServiceStatus !== null && endServiceStatus !== undefined;

    if (startStatus !== undefined && endStatus !== undefined) {
      if (startStatus! === endStatus! && startStatus! === serviceDeliveries) {
        // if both start and end case status are activeservicedeliveries then both service statuses must be set
        if (!startServiceHasValue || !endServiceHasValue) {
          return locales.controls.startAndEndServiceMustBeSelected;
        }
      }
    }

    if (serviceIsSelected && startStatus === serviceDeliveries && !startServiceHasValue) {
      return locales.controls.startServiceStatusMustBeSelected;
    }

    if (serviceIsSelected && endStatus === serviceDeliveries && !endServiceHasValue) {
      return locales.controls.endServiceStatusMustBeSelected;
    }

    if (startServiceHasValue && endServiceHasValue) {
      if (startServiceStatus! >= endServiceStatus!) {
        return locales.controls.startServiceIsLessThanEndService;
      }
    }

    return undefined;
  };

  const handleDropDownFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    if (Array.isArray(result.value)) {
      const numbers = (result.value as string[]).map((item) => {
        return parseInt(item);
      });
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: numbers,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: result.value === '' ? undefined : parseInt(result.value as string),
      }));
    }

    const startStatus = result.fieldId === 'startStatus'
      ? result.value === '' ? undefined : parseInt(result.value as string)
      : formData.startStatus;
    const endStatus = result.fieldId === 'endStatus'
      ? result.value === '' ? undefined : parseInt(result.value as string)
      : formData.endStatus;

    var caseStatusMessage = validateCaseStatuses(startStatus, endStatus);

    setRequiredStatusDetails(caseStatusMessage);
    setOverRideStatusControl(caseStatusMessage === undefined ? false : true);

    var serviceStatusMessage = validateServiceStatuses(startStatus, endStatus, formData.startServiceStatus, formData.endServiceStatus);

    setRequiredServiceStatusDetails(serviceStatusMessage);
    setOverRideServiceStatusControl(serviceStatusMessage === undefined ? false : true);

    if (serviceIsSelected) {
      if (result.fieldId === 'startStatus') {
        const startStatusDisabled =
          result.value === '' ||
          parseInt(result.value as string) !== serviceDeliveries;
        setStartServiceStatusDisabled(startStatusDisabled);
        if (startStatusDisabled) {
          setFormData((prevData) => ({
            ...prevData,
            startServiceStatus: undefined,
          }));
        }
      }

      if (result.fieldId === 'endStatus') {
        const endStatusDisabled =
          result.value === '' ||
          parseInt(result.value as string) !== serviceDeliveries;
        setEndServiceStatusDisabled(endStatusDisabled);
        if (endStatusDisabled) {
          setFormData((prevData) => ({
            ...prevData,
            endServiceStatus: undefined,
          }));
        }
      }
    }
  };

  const handleServiceDropDownFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    if (Array.isArray(result.value)) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value === '' ? undefined : parseInt(result.value as string),
    }));

    const startServiceStatus = result.fieldId === 'startServiceStatus'
      ? result.value === '' ? undefined : parseInt(result.value as string)
      : formData.startServiceStatus;
    const endServiceStatus = result.fieldId === 'endServiceStatus'
      ? result.value === '' ? undefined : parseInt(result.value as string)
      : formData.endServiceStatus;

      var serviceStatusMessage = validateServiceStatuses(formData.startStatus, formData.endStatus, startServiceStatus, endServiceStatus);

      setRequiredServiceStatusDetails(serviceStatusMessage);
      setOverRideServiceStatusControl(serviceStatusMessage === undefined ? false : true);
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const save = async () => {
    const validationResults = formRefs.map((innerRef) =>
      innerRef.current?.triggerValidation(),
    );
    const tabValid = validationResults.every((result) => result === true);
    if (!tabValid) {
      setPageState((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[0] = {
          ...updatedPages[0],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
      return;
    }

    actions
      .saveAsync(
        await getHeadersAsync(userClaims, instance),
        formData,
        id,
      )
      .then((result) => {
        if (result.isFailure) {
          showMessage(getServerErrors(locales, result.errorCode), 'negative');
          return;
        }
        showMessage(
          locales.controls.saveSuccess,
          'positive',
          `/Organisations/${orgId}/billing/${billingId}/sla`,
        );
      })
      .catch(() => {
        showMessage(locales.ApiResponses.serverErrors.default, 'negative');
      });
  };

  React.useEffect(() => {
    formRefs.map((innerRef) => innerRef.current?.triggerValidation());
  }, [overRideStatusControl, overRideServiceStatusControl, serviceIsSelected]);

  const loadDataAsync = async () => {
    const headers = await getHeadersAsync(userClaims, instance);
    await serviceActions
      .fetchServiceDefinitions(
        headers,
        intl.locale
      )
      .then((rst) => {
        if (rst.isFailure) {
          showMessage(
            locales.ApiResponses.loadingFailed,
            'negative',
            `/Organisations/${orgId}/billing`,
          );
          return;
        }
        setServiceDefinitions(rst.result!);
        setIsLoading(false);
      });

    const orgIdResult = await OrgActions.getOrgByIDAsync(
      orgId!,
      headers
    ).then((result) => {
      if (result.isFailure || !result.result) {
        showMessage(
          locales.ApiResponses.loadingFailed,
          'negative',
          `/Organisations`,
        );
        return result;
      }
      return result;
    });

    if (orgIdResult.isFailure) {
      return;
    };

    const breadcrumbs = [
      ...locales.breadcrumbs.base,
      {
        label: orgIdResult.result!.name,
        link: `/Organisations/${orgId}/edit`,
        key: orgId,
      },
      {
        label: locales.breadcrumbs.index.label,
        link: `/Organisations/${orgId}/billing/${billingId}/sla`,
        key: locales.breadcrumbs.index.key
      }
    ];

    setCaseStatuses(Enumerations.getCaseStatuses(intl.locale));
    setServiceFulfillmentStatuses(
      Enumerations.getServiceFulfillmentStatuses(intl.locale).sort(
        (a, b) => a.order - b.order,
      ),
    );

    if (id) {
      actions
        .getAsync(headers, id)
        .then((result) => {
          if (result.isFailure) {
            showMessage(
              locales.ApiResponses.loadingFailed,
              'negative',
              `/Organisations/${orgId}/billing`,
            );
            return;
          }
          setIsLoading(false);
          setServiceIsSelected(result.result!.serviceDefinitionId !== '');
          setFormData(result.result!);

          const startServiceStatusDisabled =
            result.result!.serviceDefinitionId === null ||
            result.result!.startStatus !== serviceDeliveries;
          setStartServiceStatusDisabled(startServiceStatusDisabled);

          const endServiceStatusDisabled =
            result.result!.serviceDefinitionId === null ||
            result.result!.endStatus !== serviceDeliveries;
          setEndServiceStatusDisabled(endServiceStatusDisabled);
          context?.handleCrumbUpdate([
            ...breadcrumbs,
            {
              label: result.result!.name,
              key: 'edit',
            },
          ]);
        })
        .catch(() => {
          showMessage(
            locales.ApiResponses.loadingFailed,
            'negative',
            `/Organisations/${orgId}/billing`,
          );
        });
    } else {
      setIsLoading(false);
      context?.handleCrumbUpdate([
        ...breadcrumbs,
        {
          label: locales.breadcrumbs.create.label,
          key: 'create',
        },
      ]);
    }
  };

  React.useEffect(() => {
    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      showMessage(locales.ApiResponses.permissionError, 'negative', '/');
      return;
    }

    if (!id && !billingId) {
      showMessage(locales.ApiResponses.missingBillingId, 'negative', `/Organisations/${orgId}/billing`);
      return;
    }
    loadDataAsync();
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        pages={[
          {
            mode: pageStates[0].value as ControlState,
            name: pageStates[0].key,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  fieldId="name"
                  id="name"
                  label={locales.controls.labels.name}
                  textInputType="text"
                  requiredDetails={{
                    formLabel: locales.common.requiredLabel,
                    message: `${locales.controls.labels.name} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0]}
                  onChange={handleFormChange}
                  value={formData.name}
                />
                <FormTextArea
                  displayMode="Box"
                  fieldId="description"
                  id="description"
                  label={locales.controls.labels.description}
                  onChange={handleFormChange}
                  value={formData.description}
                  ref={formRefs[1]}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="serviceDefinitionId"
                  id="serviceDefinitionId"
                  items={serviceDefinitions}
                  defaultText={locales.common.select_Default}
                  label={locales.controls.labels.relatedServiceDefinition}
                  onChange={handleServiceChange}
                  value={formData.serviceDefinitionId}
                  ref={formRefs[2]}
                  helpMessage={
                    locales.controls.helpText.relatedServiceDefinition
                  }
                />
                <FormDuration
                  displayMode="Box"
                  fieldId="duration"
                  id="duration"
                  label={locales.controls.labels.duration}
                  showDays={true}
                  showTime={false}
                  requiredDetails={{
                    formLabel: locales.common.requiredLabel,
                    message: `${locales.controls.labels.duration} ${locales.common.requiredMessage}`,
                  }}
                  onChange={handleFormChange}
                  value={formData.duration}
                  ref={formRefs[3]}
                  invalidDurationText="Invalid Duration"
                  labels={{
                    days: 'Days',
                    hours: 'Hours',
                    minutes: 'Minutes',
                  }}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="startStatus"
                  id="startStatus"
                  items={caseStatuses}
                  defaultText={locales.common.select_Default}
                  label={locales.controls.labels.startStatus}
                  onChange={handleDropDownFormChange}
                  requiredDetails={{
                    formLabel: locales.common.requiredLabel,
                    message: `${locales.controls.labels.startStatus} ${locales.common.requiredMessage}`,
                  }}
                  value={formData.startStatus}
                  ref={formRefs[4]}
                  OverRideValidation={overRideStatusControl}
                  helpMessage={requiredStatusDetails}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="startServiceStatus"
                  id="startServiceStatus"
                  items={serviceFulfillmentStatuses}
                  defaultText={locales.common.select_Default}
                  label={locales.controls.labels.startServiceStatus}
                  onChange={handleServiceDropDownFormChange}
                  value={formData.startServiceStatus}
                  hidden={!serviceIsSelected || startServiceStatusDisabled}
                  ref={formRefs[5]}
                  OverRideValidation={overRideServiceStatusControl}
                  helpMessage={requiredServiceStatusDetails}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="endStatus"
                  id="endStatus"
                  items={caseStatuses}
                  defaultText={locales.common.select_Default}
                  label={locales.controls.labels.endStatus}
                  onChange={handleDropDownFormChange}
                  requiredDetails={{
                    formLabel: locales.common.requiredLabel,
                    message: `${locales.controls.labels.endStatus} ${locales.common.requiredMessage}`,
                  }}
                  value={formData.endStatus}
                  ref={formRefs[6]}
                  OverRideValidation={overRideStatusControl}
                  helpMessage={requiredStatusDetails}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="endServiceStatus"
                  id="endServiceStatus"
                  items={serviceFulfillmentStatuses}
                  defaultText={locales.common.select_Default}
                  label={locales.controls.labels.endServiceStatus}
                  onChange={handleServiceDropDownFormChange}
                  value={formData.endServiceStatus}
                  hidden={!serviceIsSelected || endServiceStatusDisabled}
                  ref={formRefs[7]}
                  OverRideValidation={overRideServiceStatusControl}
                  helpMessage={requiredServiceStatusDetails}
                />
              </>
            ),
          },
        ]}
        backLabel="Back"
        key={'slaDefinition'}
        nextLabel="Next"
        pageChangeAction={() => { }}
      />
      <InformationButton
        key="informationSave"
        buttonDetails={{
          itemKey: 'informationSave',
          clickEvent: () => save(),
          label: locales.controls.saveButton,
          mode: 'positive',
        }}
      >
        <>{locales.controls.saveInformation}</>
      </InformationButton>
    </div>
  );
};

export default Sla_Definition_CU;
