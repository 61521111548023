import React, { useState } from 'react';
import ListTableTypes from './ListTable.types';
import PagingSize from '../paging/pagingSize/PagingSize';
import PageSelector from '../paging/pageSelector/PageSelector';
import { pagingDirection } from '../../../../sysObjects/common.types';
import PageLoader from '../../general/loading/pageLoader/PageLoader';
import './ListTable.css';
import SearchIconExpand from '../../general/searchIconExpand/SearchIconExpand';
import { SvgFilter, SvgSearch } from '../../svg';

const ListTable: React.FC<ListTableTypes.Props> & {
  Headers: React.FC<React.PropsWithChildren<{}>>;
  SearchOptions: React.FC<React.PropsWithChildren<{}>>;
} = (props) => {
  const usingServerPagination = props.serverPaging !== undefined;
  const [currentPageSize, setCurrentPageSize] = useState(props.pagingDetails.currentPageSize ?? 10);
  const [currentPage, setCurrentPage] = useState(0);
  const [startRange, setStartRange] = useState(0);
  const [totalPages, setTotalPages] = useState(Math.ceil(props.rows.length / currentPageSize));

  let startDisplay = startRange + 1;

  React.useEffect(() => {
    setStartRange(currentPage * currentPageSize);
  }, [currentPage, currentPageSize]);

  React.useEffect(() => {
    setCurrentPage(0);
    setTotalPages(Math.ceil(props.rows.length / currentPageSize));
  }, [currentPageSize]);

  const headers = React.Children.toArray(props.children).find(
    (child: any) => child.type === ListTableHeaders,
  );

  const tableOptions = React.Children.toArray(props.children).filter(
    (child: any) => child.type === ListTableSearchOptions,
  );
  const renderFooterSeverPaging = () => {
    const pagingDetails = props.serverPaging!.pagingDetails;

    if (
      pagingDetails.totalAvailableRecords <
      Math.min(...props.pagingDetails.pageSizes)
    ) {
      return null;
    }
    const fromVal =
      (pagingDetails.currentPage - 1 < 0 ? 0 : pagingDetails.currentPage - 1) *
        pagingDetails.pageSize +
      1;

    const toVal =
      (pagingDetails.currentPage - 1) * pagingDetails.pageSize +
      pagingDetails.countOfRecordsInPage;
    return (
      <div className="Table-Paging">
        <div></div>
        <div>
          <PagingSize
            label="Size"
            pageSizes={props.pagingDetails.pageSizes}
            value={pagingDetails.pageSize}
            onChange={(size) => {
              props.serverPaging!.eventHandlers.pageSizeChange(size);
            }}
          />
        </div>
        <div>
          <PageSelector
            from={fromVal}
            to={toVal}
            total={pagingDetails.totalAvailableRecords}
            ofLabel="of"
            pageChangeClick={(step: pagingDirection) => {
              if (step === 'Back') {
                props.serverPaging!.eventHandlers.pageChange(step);
              } else if (step === 'Forward') {
                props.serverPaging!.eventHandlers.pageChange(step);
              } else {
                props.serverPaging!.eventHandlers.pageChange(step);
              }
            }}
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return props.rows.length > Math.min(...props.pagingDetails.pageSizes) ? (
      <div className="Table-Paging">
        <div></div>
        <div>
          <PagingSize
            label="Size"
            pageSizes={props.pagingDetails.pageSizes}
            value={currentPageSize}
            onChange={(size) => {
              setCurrentPageSize(size);
            }}
          />
        </div>
        <div>
          <PageSelector
            from={startDisplay}
            to={
              startDisplay + currentPageSize > props.rows.length
                ? props.rows.length
                : startRange + currentPageSize
            }
            total={props.rows.length}
            ofLabel="of"
            pageChangeClick={(step: pagingDirection) => {
              let newPage = currentPage;

              switch (step) {
                case 'Start':
                  newPage = 0;
                  break;
                case 'Back':
                  newPage = Math.max(currentPage - 1, 0);
                  break;
                case 'Forward':
                  newPage = Math.min(currentPage + 1, totalPages - 1);
                  break;
                case 'End':
                  newPage = totalPages - 1;
                  break;
              }
          
              setCurrentPage(newPage);
            }}
          />
        </div>
      </div>
    ) : null;
  };
  return (
    <div className={`List-Table ${props.className}`}>
      <div
        className={`Table-Name Font-Heading${props.search && props.filter ? ' Three-Col' : !props.filter && !props.search ? ' One-Col' : ' Two-Col'}`}
      >
        <span>{props.labels.tableName}</span>
        {props.search && (
          <SearchIconExpand
            testId="search"
            isExpanded={props.search.isVisible}
            onExpand={props.search.onStateChange}
            icon={<SvgSearch className="icon" />}
          />
        )}
        {props.filter && (
          <SearchIconExpand
            testId="filter"
            isExpanded={props.filter.isVisible}
            onExpand={props.filter.onStateChange}
            icon={<SvgFilter className="icon" />}
          />
        )}
        {tableOptions}
      </div>

      <div className="Table">
        {headers}
        {props.isLoading ? (
          <PageLoader alt="Loading Cases" />
        ) : (
          <>
            <div
              key={'MainTable'}
              className={`Table-Body Text-Regular ${
                usingServerPagination
                  ? props.serverPaging!.pagingDetails.totalAvailableRecords ===
                    0
                    ? 'IsEmpty'
                    : ''
                  : !props.rows
                    ? 'IsEmpty'
                    : ''
              }`}
            >
              {props.defaultItem !== undefined &&
              props.defaultItemPlacing === 'top'
                ? props.defaultItem
                : null}
              {props.rows !== undefined &&
              props.rows !== null &&
              props.rows.length > 0
                ? usingServerPagination
                  ? props.rows.map((i) => i)
                  : props.rows
                      .slice(startRange, startRange + currentPageSize)
                      .map((i) => i)
                : !props.defaultItem && (
                    <div className="Empty-Message Text-Strong">
                      {props.labels.emptyMessage}
                    </div>
                  )}
              {props.defaultItem !== undefined &&
              props.defaultItemPlacing !== 'top'
                ? props.defaultItem
                : null}
            </div>
            {usingServerPagination ? renderFooterSeverPaging() : renderFooter()}
          </>
        )}
      </div>
    </div>
  );
};
const ListTableHeaders: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return <div className="Head-Row Text-Regular">{children}</div>;
};

const ListTableSearchOptions: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return children ? <div className="SearchFilter">{children}</div> : null;
};

ListTable.Headers = ListTableHeaders;
ListTable.SearchOptions = ListTableSearchOptions;
export default ListTable;
