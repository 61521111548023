import React from 'react';
import PricingDefinitionItemRow from '../pricingItemRow/PricingItemRow';
import { PricingItemListTypes } from './PricingItemList.types';
import { PricingDefinitionItemTypes } from '../pricingItemRow/PricingItemRow.types';
import './PricingItemList.css';
import PageLoader from '../../../sharedControls/general/loading/pageLoader/PageLoader';
import ListTable from '../../../sharedControls/tables/listTable/ListTable';

const PricingItemList: React.FC<PricingItemListTypes.Props> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [rows, setRows] = React.useState<PricingDefinitionItemTypes.Item[]>([]);

  React.useEffect(() => {
    setRows(props.rows);
    setIsLoading(false);
  }, [props.rows]);

  return isLoading ? (
    <PageLoader alt={props.labels.common.load} />
  ) : (
    rows && (
      <>
        <ListTable
          isLoading={false}
          labels={{
            of: props.labels.table.of,
            size: props.labels.table.size,
            tableName: props.labels.table.tableName,
            emptyMessage: props.labels.table.emptyMessage,
          }}
          pagingDetails={props.pagingDetails}
          rows={
            rows.length > 0
              ? rows.map((item) => (
                  <PricingDefinitionItemRow
                    key={item.id ?? item.selectedServiceId}
                    item={item}
                    labels={props.labels.rowLabels}
                  />
                ))
              : []
          }
          className="Pricing-Table"
        >
          <ListTable.Headers>
            <div key="StatusCol"></div>
            {props.labels.table.tableHeaders.map((header, hI) => (
              <div key={`Pricing_Header_${hI}`}>{header}</div>
            ))}
          </ListTable.Headers>
        </ListTable>
      </>
    )
  );
};

export default PricingItemList;
