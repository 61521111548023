import React, { useContext } from 'react';
import { LuClock } from 'react-icons/lu';
import './BookingRow.css';
import AdvancedDatePickerTypes from './advancedDatePicker/AdvancedDatePicker.types';
import { FaRegClock, FaUserDoctor } from 'react-icons/fa6';
import {
  formatServiceName,
  getServerErrors,
  LocalEnumerations,
} from '../../../../../systemUtils/common/CommonHelpers';
import DateHelper from '../../../../../systemUtils/common/DateHelpers';
import { UserClaimsContext } from '../../../../sharedControls/contexts/UserClaimsContext';
import { KeyValuePair } from '../../../../../sysObjects/common.types';
import Duration from '../../../../sharedControls/targetedControls/duration/Duration';
import Button from '../../../../sharedControls/general/button/Button';
import StatusLabel from '../../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import StatusBlock from '../../../../sharedControls/reusableBlocks/statusBlock/StatusBlock';
import RowLoader from '../../../../sharedControls/general/loading/rowLoader/RowLoader';
import BookingRowTypes from './BookingRow.types';
import AdvancedDatePicker from './advancedDatePicker/AdvancedDatePicker';
import { CaseManagerRoles } from '../../tabs/caseServicesTab/CaseServicesTab';
import Expander from '../../../../sharedControls/general/expander/Expander';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import ButtonBoxTypes from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import { format } from 'path';

const BookingRow: React.FC<BookingRowTypes.Props> = (props) => {
  const UserCanSelect = () => {
    const bookingDetails = props.service_Details.bookingDetails;

    if (!bookingDetails || bookingDetails.state.canUpdateStatus === false) {
      return false;
    }
    const { statusId } = bookingDetails;
    return statusId === 0 || statusId === 1;
  };

  const [isLoading, setLoading] = React.useState(false);
  const [isBooking, setIsBooking] = React.useState(false);

  const [chargeable, setChargeable] = React.useState<number>(1);
  const [previousChargeable, setPreviousChargeable] = React.useState<number>(1);
  const chargeableChanged = props.service_Details.bookingDetails
    ? (chargeable === 1) !== props.service_Details.bookingDetails.isChargeable
    : false;

  const [replace, setReplace] = React.useState<number | null>(null);
  const [currentState, setCurrentState] = React.useState<number>(0);
  const [canSelect, setCanSelect] = React.useState<boolean>(false);
  const [previousState, setPreviousState] = React.useState<number>(0);
  const [stateEdited, setStateEdited] = React.useState<boolean>(false);
  const [isCancelling, setIsCancelling] = React.useState<boolean>(false);
  const [isSettingOutcome, setIsSettingOutcome] =
    React.useState<boolean>(false);
  const [newOutcome, setNewOutcome] = React.useState<string>();
  const [highlightDates, setHighlightDates] = React.useState<
    AdvancedDatePickerTypes.TimeSlot[]
  >([]);
  const [date, setDate] = React.useState<Date>(new Date());

  const [duration, setDuration] = React.useState<string>('01:00:00');

  const [bookingDetails, setBookingDetails] = React.useState<
    BookingRowTypes.BookingDetails | undefined
  >(props.service_Details.bookingDetails);
  const { userClaims } = useContext(UserClaimsContext);

  React.useEffect(() => {
    const originalState = props.service_Details.bookingDetails?.statusId ?? 0;
    const originalChargeable = props.service_Details.bookingDetails
      ?.isChargeable
      ? 1
      : 0;
    setDuration(props.service_Details.duration ?? '01:00:00');
    setCurrentState(originalState);
    setPreviousState(originalState);
    setChargeable(originalChargeable);
    setPreviousChargeable(originalChargeable);
    setCanSelect(UserCanSelect());
    setDate(props.startDate);
  }, [props.service_Details]);

  const handleChangedState = async (value: KeyValuePair<number>) => {
    if (currentState === value.key) {
      return;
    }

    setStateEdited(true);
    setPreviousState(currentState);
    setCurrentState(value.key);

    if (
      props.eventHandlers.stateChanged === undefined ||
      !props.eventHandlers.stateChanged
    ) {
      return;
    }

    setNewOutcome(value.value);

    if (value.key === 2 || value.key === 3 || value.key === 5) {
      setIsCancelling(true);
    } else {
      setChargeable(0);
    }

    setIsSettingOutcome(true);
  };

  const clearForm = () => {
    setIsCancelling(false);
    setIsSettingOutcome(false);
    setCurrentState(previousState);
    setChargeable(previousChargeable);
    setStateEdited(false);
    setReplace(null);
  };

  const handleConfirm = async () => {
    if (!props.eventHandlers.stateChanged) {
      return;
    }

    setIsBooking(true);

    if (isSettingOutcome) {
      await props.eventHandlers
        .stateChanged({
          appointmentId: bookingDetails?.appointmentId ?? '',
          serviceToFulfillId: props.service_Details.serviceToFulfillId,
          status: currentState,
          addReplacementServiceToFulfill: replace === 0 ? false : true,
          isChargeable: chargeable === 0 ? false : true,
        })
        .then((result) => {
          if (result.isFailure) {
            if (props.eventHandlers.onError !== undefined) {
              props.eventHandlers.onError(props.labels.bookingErrorMessage);
            }
          }

          clearForm();
          props.eventHandlers.setRefreshRequired();
        });

      return;
    }

    if (!chargeableChanged) {
      return;
    }

    await props.eventHandlers
      .updateChargeable({
        appointmentId: bookingDetails?.appointmentId ?? '',
        serviceToFulfillId: props.service_Details.serviceToFulfillId,
        status: currentState,
        isChargeable: chargeable === 0 ? false : true,
        addReplacementServiceToFulfill:
          chargeable === 1 ? (replace === 0 ? false : true) : undefined,
      })
      .then((result) => {
        if (result.isFailure) {
          if (props.eventHandlers.onError !== undefined) {
            props.eventHandlers.onError(
              getServerErrors(props.labels, result.errorCode),
            );
          }
          clearForm();
          props.eventHandlers.setRefreshRequired();
          return;
        }

        clearForm();
        props.eventHandlers.setRefreshRequired();
      });
  };

  const handleExpand = (state: boolean) => {
    if (
      props.eventHandlers.onExpandedChanged === undefined ||
      props.eventHandlers.onExpandedChanged === null
    ) {
      return;
    }
    props.eventHandlers.onExpandedChanged(props.service_Details.rowId, state);

    if (props.service_Details.bookingDetails !== undefined) {
      return;
    }
    if (state) {
      handleMonthChange({
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      });
    }
  };

  const handleMonthChange = (
    date: AdvancedDatePickerTypes.SimpleDate,
    incomingDuration?: string,
  ) => {
    setDate(new Date(date.year, date.month, date.day));
    const localDuration = incomingDuration ?? duration;
    if (props.eventHandlers.monthChange !== undefined) {
      setLoading(true);
      props.eventHandlers
        .monthChange({
          date: date,
          serviceId: props.service_Details.serviceDefinitionId,
          duration: localDuration,
        })
        .then((result) => {
          if (result.isFailure) {
            if (props.eventHandlers.onError !== undefined) {
              props.eventHandlers.onError(props.labels.monthChangeErrorMessage);
            }
            setHighlightDates([]);
            return;
          }
          setHighlightDates(result.result!);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const renderHasBookingRow = () => {
    return bookingDetails !== undefined ? (
      <div className="Has-Booking">
        <div>{props.labels.aptDate}</div>
        <div className="Value-Column">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`aptDate_${bookingDetails?.appointmentId}`}
            content={<FaRegClock />}
          />
          {DateHelper.getLocalDateString(
            bookingDetails?.bookingDate || '',
            props.intl,
            'MMMM',
          )}
        </div>
        <div>{props.labels.time}</div>
        <div className="Value-Column">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`aptTime_${bookingDetails?.appointmentId}`}
            content={<FaRegClock />}
          />
          {DateHelper.getLocalTimeString(
            bookingDetails?.bookingDate || '',
            props.intl,
          )}
        </div>
        <div>{props.labels.person}</div>
        <div className="Value-Column">
          <StatusBlock
            boxSize="small"
            boxState="positive"
            id={`aptPerson_${bookingDetails?.appointmentId}`}
            content={<FaUserDoctor />}
          />
          {bookingDetails?.name}
        </div>
        <div>{props.labels.state}</div>
        <div className="Booking-State Value-Column State-Selector">
          {renderSelector()}
        </div>
        {stateEdited || chargeableChanged ? renderCommitBlock() : null}
      </div>
    ) : null;
  };

  const renderMakeBookedRow = () => {
    return (
      <div className="make-booking">
        <div>
          <Duration
            fieldId="duration"
            id="duration"
            label={props.labels.durationName}
            displayMode="Column"
            value={duration}
            smallestTimeInMinutes={30}
            onChange={(e) => {
              setDuration(e.value.time!);
              if (e.value.isValid && e.value.fireResult) {
                handleMonthChange(
                  {
                    day: date.getDate(),
                    month: date.getMonth(),
                    year: date.getFullYear(),
                  },
                  e.value.time,
                );
              }
            }}
            labels={{
              hours: props.labels.durationHours,
              minutes: props.labels.durationMinutes,
            }}
            invalidDurationText=""
          />
        </div>
        <AdvancedDatePicker
          id={props.service_Details.serviceToFulfillId}
          labels={{
            bookButton: props.labels.bookButton,
            resetButton: props.labels.resetButton,
            daysOfWeek: props.labels.daysOfWeek,
            monthNames: props.labels.monthNames,
          }}
          startDayOfWeek={props.startDayOfWeek}
          highlightDates={highlightDates}
          key={`DatePicker_${props.service_Details.serviceToFulfillId}`}
          startingDate={new Date(props.startDate)}
          value={date}
          maxAdvancedMonths={props.maxAdvancedMonths}
          events={{
            onError: props.eventHandlers.onError,
            onBooking: (result) => {
              if (props.eventHandlers.bookingSelected === undefined) {
                return;
              }

              props.eventHandlers
                .bookingSelected({
                  userId: result.userId,
                  date: result.date,
                  Time: result.Time,
                  serviceToFulfillId: props.service_Details.serviceToFulfillId,
                  duration: duration,
                  username: result.username,
                })
                .then((rsr) => {
                  if (rsr.isSuccess) {
                    clearForm();
                    props.eventHandlers.setRefreshRequired();
                  }
                });
            },
            monthChange: handleMonthChange,
          }}
        />
      </div>
    );
  };

  const renderAwaitingRow = () => {
    return (
      <div className="booking">
        <div />
        <StatusLabel
          label={props.labels.awaitingBooking}
          renderAs="div"
          key={`book_label_${props.service_Details.serviceToFulfillId}`}
          status="neutral"
        />
        {props.canMakeBooking && (
          <Button
            itemKey="book"
            key={`book_${props.service_Details.serviceToFulfillId}`}
            label="Book"
            mode="positive"
            clickEvent={() => handleExpand(!props.service_Details.isExpanded)}
          />
        )}
      </div>
    );
  };

  const renderServiceName = () => {
    return (
      <div className="Service-Name">
        {formatServiceName(
          props.service_Details.serviceName,
          props.service_Details.serviceReference,
        )}
      </div>
    );
  };

  const renderCommitBlock = () => {
    const locked = !shouldContinueOptionsBeDisplayed() || replace === null;
    return (
      <>
        <div>{props.labels.commit}</div>
        <div className="Value-Column">
          <ButtonBox
            id={`commit_buttons_${props.service_Details.serviceToFulfillId}`}
            buttons={[
              {
                label: props.labels.confirmCommit,
                id: `confirm_commit_${props.service_Details.serviceToFulfillId}`,
                controlState: locked ? 'locked' : 'positive',
                onClick: () => handleConfirm(),
              },
              {
                label: props.labels.cancelCommit,
                id: `cancel_commit_${props.service_Details.serviceToFulfillId}`,
                controlState: locked ? 'locked' : 'positive',
                onClick: () => clearForm(),
              },
            ]}
          />
        </div>
      </>
    );
  };

  const renderSelector = () => {
    if (bookingDetails === null) {
      return;
    }

    let selectableOptions: number[] = [];
    let date = new Date();
    let isCaseManager = CaseManagerRoles.includes(userClaims?.user?.role ?? -1);

    if (
      date.getTime() <= new Date(bookingDetails!.bookingDate!).getTime() &&
      isCaseManager
    ) {
      selectableOptions.push(LocalEnumerations.AppointmentStatuses.Confirmed);
      selectableOptions.push(LocalEnumerations.AppointmentStatuses.Declined);
    }

    if (isCaseManager) {
      selectableOptions.push(LocalEnumerations.AppointmentStatuses.Cancelled);
    }

    if (date.getTime() > new Date(bookingDetails!.bookingDate)!.getTime()) {
      selectableOptions.push(
        LocalEnumerations.AppointmentStatuses.LexxicNonAttendance,
      );
      selectableOptions.push(
        LocalEnumerations.AppointmentStatuses.CustomerNonAttendance,
      );
      selectableOptions.push(LocalEnumerations.AppointmentStatuses.Attended);
    }

    return (
      <>
        {canSelect ? (
          <ButtonBox
            buttons={
              props.stateItems
                .filter((x) => selectableOptions.includes(x.key))
                .map((item) => ({
                  label: item.value,
                  id: item.key.toString(),
                  controlState: 'positive',
                  isChecked: currentState === item.key,
                  onClick: () =>
                    handleChangedState({ key: item.key, value: item.value }),
                })) as ButtonBoxTypes.Button[]
            }
            key={`ButtonBox_${props.service_Details.serviceToFulfillId}`}
            id={`ButtonBox_${props.service_Details.serviceToFulfillId}`}
            displayBorder={true}
          />
        ) : (
          <StatusLabel
            label={
              props.stateItems.find((x) => x.key === currentState)?.value ?? ''
            }
            renderAs="div"
            key={`book_label_${props.service_Details.serviceToFulfillId}`}
            status="neutral"
          />
        )}
        {renderExpandedSelector()}
      </>
    );
  };

  const shouldChargeabilityBeDisplayed = () => {
    const chargeableStates = [
      LocalEnumerations.AppointmentStatuses.Attended,
      LocalEnumerations.AppointmentStatuses.LexxicNonAttendance,
      LocalEnumerations.AppointmentStatuses.CustomerNonAttendance,
      LocalEnumerations.AppointmentStatuses.Declined,
      LocalEnumerations.AppointmentStatuses.Cancelled,
    ];

    return (
      chargeableStates.includes(currentState) &&
      CaseManagerRoles.includes(userClaims?.user?.role ?? -1) &&
      !props.service_Details.bookingDetails?.isInvoiced
    );
  };

  const shouldContinueOptionsBeDisplayed = (): boolean => {
    const negativeStatuses = [
      LocalEnumerations.AppointmentStatuses.LexxicNonAttendance,
      LocalEnumerations.AppointmentStatuses.CustomerNonAttendance,
      LocalEnumerations.AppointmentStatuses.Declined,
      LocalEnumerations.AppointmentStatuses.Cancelled,
    ];

    return (
      CaseManagerRoles.includes(userClaims?.user?.role ?? -1) &&
      negativeStatuses.includes(currentState) &&
      !props.service_Details.bookingDetails?.isInvoiced &&
      chargeable === 1 &&
      chargeableChanged
    );
  };

  const updateChargeable = (value: 0 | 1) => {
    if (chargeable === value) {
      return;
    }

    setPreviousChargeable(chargeable);
    setChargeable(value);
  };

  const renderExpandedSelector = () => {
    return (
      <>
        {shouldChargeabilityBeDisplayed() ? (
          <ButtonBox
            id={'chargeability-buttons'}
            title={props.labels.chargeableHelpText}
            buttons={[
              {
                label: props.labels.chargeable,
                id: `chargeable_${props.service_Details.serviceToFulfillId}`,
                controlState: 'positive',
                isChecked: chargeable === 1,
                onClick: () => updateChargeable(1),
              },
              {
                label: props.labels.nonChargeable,
                id: `non_chargeable_${props.service_Details.serviceToFulfillId}`,
                controlState: 'positive',
                isChecked: chargeable === 0,
                onClick: () => updateChargeable(0),
              },
            ]}
          />
        ) : null}
        {isSettingOutcome || shouldContinueOptionsBeDisplayed() ? (
          <ButtonBox
            id={'continue-service-buttons'}
            title={props.labels.continueHelpText}
            buttons={[
              {
                label: props.labels.continue,
                id: `continue_${props.service_Details.serviceToFulfillId}`,
                controlState: 'positive',
                isChecked: replace === 1,
                onClick: () => setReplace(1),
              },
              {
                label: props.labels.doNotContinue,
                id: `non_continue_${props.service_Details.serviceToFulfillId}`,
                controlState: 'positive',
                isChecked: replace === 0,
                onClick: () => setReplace(0),
              },
            ]}
          />
        ) : null}
      </>
    );
  };

  const getCurrentStatusText = () => {
    return props.stateItems.find((x) => x.key === currentState)?.value ?? '';
  };

  const renderHasBooking = () => {
    return (
      bookingDetails && (
        <div className="Booking-summary lexxic-text">
          <div />
          <StatusLabel
            label={getCurrentStatusText()}
            renderAs="div"
            key={`book_label_${props.service_Details.serviceToFulfillId}`}
            status="neutral"
          />
          <label>
            <StatusBlock
              boxSize="small"
              boxState="positive"
              id={`status_Cal_${props.service_Details.serviceToFulfillId}`}
              showIcon={false}
              key={`status_${props.service_Details.serviceToFulfillId}`}
              content={<LuClock scale={1} size={12} className="icon lexxic" />}
            />
            {DateHelper.getLocalTimeString(
              bookingDetails?.bookingDate || '',
              props.intl,
            )}
          </label>
          <label>
            <StatusBlock
              boxSize="small"
              boxState="positive"
              id={`status_Cal_${props.service_Details.serviceToFulfillId}`}
              showIcon={false}
              key={`status_${props.service_Details.serviceToFulfillId}`}
              content={<LuClock scale={1} size={12} className="icon lexxic" />}
            />
            {DateHelper.getLocalDateString(
              bookingDetails?.bookingDate || '',
              props.intl,
              'MMMM',
            )}
          </label>
        </div>
      )
    );
  };

  return (
    <div
      className={`Booking-Row lexxic-text ${
        props.service_Details.isExpanded ? 'expanded' : ''
      }`}
    >
      {isLoading ? (
        <RowLoader size="medium" />
      ) : (
        <Expander
          key={`BookingRow_${props.service_Details.serviceToFulfillId}`}
          id={`BookingRow_${props.service_Details.serviceToFulfillId}`}
          isExpanded={props.service_Details.isExpanded}
          eventHandler={{
            onClick: () =>
              props.service_Details.bookingDetails !== undefined
                ? handleExpand(!props.service_Details.isExpanded)
                : props.canMakeBooking
                  ? handleExpand(!props.service_Details.isExpanded)
                  : null,
          }}
        />
      )}
      {renderServiceName()}

      {props.service_Details.isExpanded && !isLoading
        ? bookingDetails === undefined
          ? renderMakeBookedRow()
          : renderHasBookingRow()
        : bookingDetails === undefined && !isLoading
          ? renderAwaitingRow()
          : renderHasBooking()}
    </div>
  );
};

export default BookingRow;
