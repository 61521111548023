import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import CaseUtils from '../../../systemUtils/case/caseUtils';
import { ControlState } from '../../../sysObjects/common.types';
import {
  Case_Types,
  CaseDetail_Types,
} from '../../../sysObjects/apiModels/Case.types';
import { useMsal } from '@azure/msal-react';
import CaseLayoutRowTypes from '../../../systemComponents/targetedPageControls/case/caseTableRow/CaseTableRowProps.types';
import {
  getHeadersAsync,
  sortServiceLevelAgreementAsc,
  sortStatusHistoryDesc,
} from '../../../systemUtils/common/CommonHelpers';
import DateHelpers from '../../../systemUtils/common/DateHelpers';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import CasesTableRow from '../../../systemComponents/targetedPageControls/case/caseTableRow/CasesTableRow';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import './index.css';
import oDataTypes from '../../../sysObjects/apiModels/oDataTypes';
import SharedLogger, {
  LogLevel,
} from '../../../systemUtils/common/logging/SharedLogger';
import CaseIndex_Types from './CaseIndex.types';
import { pagingDirection } from '../../../sysObjects/common.types';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import BaseBlock from '../../../systemComponents/sharedControls/reusableBlocks/baseBlock/BaseBlock';

import {
  SvgEmptyRadio,
  SvgFilter,
  SvgGood,
  SvgSearch,
} from '../../../systemComponents/sharedControls/svg';
import Button from '../../../systemComponents/sharedControls/general/button/Button';
import StatusBlock from '../../../systemComponents/sharedControls/reusableBlocks/statusBlock/StatusBlock';

const CaseIndexPage = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);

  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();
  const code = 'LXNC-';

  const { userSettings, setUserSettings } = useUserSettingsContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [gettingData, setGettingData] = React.useState<boolean>(true);
  const [cases, setCases] = React.useState<CaseLayoutRowTypes.Props[]>([]);
  const [showTextSearch, setShowTextSearch] = React.useState<boolean>(false);
  const [showFilter, setShowFilter] = React.useState<boolean>(false);
  const [myCases, setMyCases] = React.useState<boolean>(
    userSettings.defaultMyCasesFilter === true ||
      userSettings.defaultMyCasesFilter === null ||
      userSettings.defaultMyCasesFilter === undefined,
  );
  const [inputSearchValue, setInputSearchValue] = React.useState<string>('');
  const [inputCaseReference, setInputCaseReference] =
    React.useState<string>('');
  const [caseRefIsValid, setCaseRefIsValid] = React.useState<boolean | null>(
    null,
  );
  const [textSearchIsValid, setTextSearchIsValid] = React.useState<
    boolean | null
  >(null);
  const [searchType, setSearchType] =
    React.useState<CaseIndex_Types.SearchType>('none');

  React.useState<string>('');
  const [pagingDetails, setPagingDetails] =
    React.useState<oDataTypes.PagingModel>({
      pageSize: userSettings.startingPageSize,
      currentPage: 1,
      totalAvailableRecords: 0,
      totalPages: 0,
      nextPageUrl: null,
      previousPageUrl: null,
      countOfRecordsInPage: 0,
    });

  useEffect(() => {
    context?.handleCrumbUpdate(locales.breadcrumbs);
  }, []);

  useEffect(() => {
    fetchCases(
      {
        skip: 0,
        top: pagingDetails.pageSize,
      },
      null,
    );
  }, [pagingDetails.pageSize, myCases]);

  const handleNewContact = (caseId: string, newContact: CaseLayoutRowTypes.Contact) => {
    setCases((prevCases) => {

      const caseIndex = prevCases.findIndex((x) => x.id === caseId);
      if (caseIndex === -1) {
        return prevCases;
      }
  
      const updatedCase = {
        ...prevCases[caseIndex],
        contacts: [...prevCases[caseIndex].contacts || [], newContact],
      };
  
      const updatedCases = [...prevCases];
      updatedCases[caseIndex] = updatedCase;
      return updatedCases;
    });
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const updateUserSettings = (defaultToMyCases: boolean) => {
    const newUserVars = {
      ...userSettings,
      defaultMyCasesFilter: defaultToMyCases,
    };
    setUserSettings(newUserVars);
    localStorage.setItem('Connect-UserSettings', JSON.stringify(newUserVars));
  };

  const fetchCases = async (
    caseDetails: CaseIndex_Types.FetchCasesModel,
    incomingSearchType: CaseIndex_Types.SearchType,
  ) => {
    setGettingData(true);

    let localSearchType = searchType;
    if (incomingSearchType !== null) {
      setSearchType(incomingSearchType);
      localSearchType = incomingSearchType;
    }

    let query: CaseDetail_Types.findCaseQuery = {};

    if (UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      query.caseManagerId = myCases ? userClaims?.user?.userAccountId : null;
    } else {
      query.assessorId = userClaims?.user?.userAccountId;
    }

    
    if (localSearchType === 'caseReference') {
      query.caseReference = code + inputCaseReference;
    }

    if (localSearchType === 'text') {
      query.searchText = inputSearchValue;
    }

    CaseUtils.findCasesAsync(await getHeadersAsync(userClaims, instance), {
      apiQuery: query,
      oDataQuery: {
        top: caseDetails!.top,
        skip: caseDetails!.skip,
      },
    })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure) {
          context?.handleMessage({
            message: locales.ApiResponses.loadingFailed,
            alertType: 'negative',
          });
          navigate('/');
          return;
        }
        setData(result.result!);
      })
      .catch((error) => {
        SharedLogger(LogLevel.Error, error);
      });
  };

  const setData = (data: oDataTypes.PagedResult<Case_Types.FindCaseResult>) => {
    setPagingDetails(data.pagingMetaData);
    setCases(
      data.resultData.map((caseDetails: Case_Types.FindCaseResult) => {
        const history = caseDetails.statusHistory.sort((x, y) =>
          sortStatusHistoryDesc(x, y),
        );

        const assignedHistory = caseDetails.statusHistory
          .filter((x) => x.value === 1)
          .sort((x, y) => sortStatusHistoryDesc(x, y));

        const sla = caseDetails.caseSlas
          .filter((x) => x.completedDate === null)
          .sort((x, y) => sortServiceLevelAgreementAsc(x, y));

        return {
          idLabel: caseDetails.caseReference,
          intl: intl,
          modLabel: '',
          contacts: caseDetails.caseContacts.map((contact) => {
            return {
              name: `${contact.name}`,
              email: contact.emailAddress,
              relationship: contact.relationshipToCustomer,
              phone: contact.telephoneNumber,
            };
          }),
          id: caseDetails.id,
          customerName: `${caseDetails.customerName} ${caseDetails.customerSurname}`,
          refOrganization: caseDetails.referrerOrganisationName,
          statusLabel: locales.caseStatusLabels[history[0].value || 0],
          categoryLabel: '',
          labels: locales.caseTable.labels.row,
          slas: caseDetails.caseSlas.map((sla) => {
            return {
              id: sla.id,
              slaDefinitionId: sla.slaDefinitionId,
              name: sla.name,
              duration: sla.duration,
              startDate: DateHelpers.parseDate(sla.startDate)!,
              dueDate:
                sla.dueDate === undefined || sla.dueDate === null
                  ? null
                  : DateHelpers.parseDate(sla.dueDate),
              completedDate:
                sla.completedDate === undefined || sla.completedDate === null
                  ? null
                  : DateHelpers.parseDate(sla.completedDate),
            };
          }),
          assignedDate:
            assignedHistory.length > 0
              ? DateHelpers.getLocalDateString(
                  assignedHistory[0].createdDateTime,
                  intl,
                  'MMMM',
                )
              : '',
          assignedTime:
            assignedHistory.length > 0
              ? DateHelpers.getLocalTimeString(
                  assignedHistory[0].createdDateTime,
                  intl,
                )
              : '',
          rowButtons: [
            {
              label: locales.ViewCase,
              controlState: 'positive',
              onClick: () => navigate(`/cases/view/${caseDetails.id}`),
            },
          ],
          events: {
            onError: (error: string) => { 
              showMessage(error, 'negative');
            },
            onAddContact: (caseId: string, contact: CaseLayoutRowTypes.Contact) => {
              handleNewContact(caseId, contact);
            }
          },
          messages: {
            addContact: {
              requiredField: locales.caseTable.messages.addContact.requiredField,
              errorMessage: locales.caseTable.messages.addContact.errorMessage
            }
          }
        };
      }),
    );
    setGettingData(false);
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={gettingData}
        serverPaging={{
          pagingDetails: pagingDetails,
          eventHandlers: {
            pageSizeChange: (size: number) => {
              setPagingDetails({
                ...pagingDetails,
                pageSize: size,
              });
            },
            pageChange: (direction: pagingDirection, url?: string) => {
              if (direction === 'Back') {
                fetchCases(
                  {
                    skip: pagingDetails.currentPage - 2,
                    top: pagingDetails.pageSize,
                  },
                  null,
                );
              } else if (direction === 'Forward') {
                fetchCases(
                  {
                    skip: pagingDetails.currentPage,
                    top: pagingDetails.pageSize,
                  },
                  null,
                );
              } else if (direction === 'Start') {
                fetchCases(
                  {
                    skip: 0,
                    top: pagingDetails.pageSize,
                  },
                  null,
                );
              } else if (direction === 'End') {
                fetchCases(
                  {
                    skip: pagingDetails.totalPages - 1,
                    top: pagingDetails.pageSize,
                  },
                  null,
                );
              }
            },
          },
        }}
        filter={
          UserCore.userIsCaseManagerOrHigher(userClaims!.user!)
            ? {
                isVisible: showFilter,
                onStateChange: (state: boolean) => setShowFilter(state),
              }
            : undefined
        }
        search={{
          isVisible: showTextSearch,
          onStateChange: (state: boolean) => setShowTextSearch(state),
        }}
        labels={locales.caseTable.labels}
        rows={
          cases.map((row, index) => {
            return (
              <CasesTableRow
                key={`CaseRow-${index}`}
                modLabel={''}
                intl={intl}
                buttons={row.buttons}
                rowButtons={row.rowButtons}
                id={row.id}
                idLabel={row.idLabel}
                customerName={row.customerName}
                refOrganization={row.refOrganization}
                statusLabel={row.statusLabel}
                slas={row.slas}
                assignedDate={row.assignedDate}
                assignedTime={row.assignedTime}
                labels={row.labels}
                contacts={row.contacts}
                events={row.events}
                messages={row.messages}
              />
            );
          }) || []
        }
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        className="case-layout-container"
      >
        {(showFilter === true || showTextSearch === true) && (
          <ListTable.SearchOptions>
            {showTextSearch && (
              <div
                className={`Search-Box${showFilter && showTextSearch ? ' Both-Shown' : ''}`}
              >
                <BaseBlock
                  boxSize="medium"
                  className="Icon-Holder"
                  content={<SvgSearch className="dark-icon" />}
                />
                <input
                  type="text"
                  className="form-textBox case-reference"
                  value={code + inputCaseReference}
                  onBlur={(evt) => {
                    if (evt.target.value.replace(code, '') === '') {
                      setCaseRefIsValid(null);
                    }
                  }}
                  onKeyPress={(evt) => {
                    if (!/[0-9]/.test(evt.key)) {
                      evt.preventDefault();
                    }
                  }}
                  onChange={(evt) => {
                    const value = evt.target.value;
                    if (value.length < code.length) {
                      setInputCaseReference('');
                      return;
                    }

                    if (/^\d*$/.test(value)) {
                      setInputCaseReference(value);
                    }
                    setInputCaseReference(evt.target.value.replace(code, ''));
                    if (evt.target.value.replace(code, '') !== '') {
                      setCaseRefIsValid(true);
                    } else {
                      setCaseRefIsValid(null);
                    }
                  }}
                />
                <StatusBlock
                  boxSize="medium"
                  className="icon"
                  showIcon={true}
                  boxState={
                    caseRefIsValid === null
                      ? 'neutral'
                      : caseRefIsValid === true
                        ? 'positive'
                        : 'negative'
                  }
                />
                <Button
                  itemKey="searchCaseRefButton"
                  children={<SvgSearch className="icon" />}
                  label={locales.searchAndFilter.btnSearch}
                  mode="positive"
                  clickEvent={() => {
                    if (inputCaseReference !== '') {
                      setCaseRefIsValid(true);
                      fetchCases(
                        {
                          skip: 0,
                          top: pagingDetails.pageSize,
                        },
                        'caseReference',
                      );
                    } else {
                      setCaseRefIsValid(false);
                    }
                  }}
                />
                <input
                  type="text"
                  className="form-textBox"
                  value={inputSearchValue}
                  onChange={(evt) => {
                    setInputSearchValue(evt.target.value);
                    if (evt.target.value !== '') {
                      setTextSearchIsValid(true);
                    } else {
                      setTextSearchIsValid(null);
                    }
                  }}
                  onBlur={(evt) => {
                    if (evt.target.value === '') {
                      setTextSearchIsValid(null);
                    }
                  }}
                />
                <StatusBlock
                  boxSize="medium"
                  className="icon"
                  showIcon={true}
                  boxState={
                    textSearchIsValid === null
                      ? 'neutral'
                      : textSearchIsValid === true
                        ? 'positive'
                        : 'negative'
                  }
                />
                <Button
                  itemKey="searchTextButton"
                  children={<SvgSearch className="icon" />}
                  label={locales.searchAndFilter.btnSearch}
                  mode="positive"
                  clickEvent={() => {
                    if (inputSearchValue !== '') {
                      setTextSearchIsValid(true);
                      fetchCases(
                        {
                          skip: 0,
                          top: pagingDetails.pageSize,
                        },
                        'text',
                      );
                    } else {
                      setTextSearchIsValid(false);
                    }
                  }}
                />
              </div>
            )}
            {showFilter && (
              <div className="Filter-Box">
                <BaseBlock
                  boxSize="medium"
                  className="Icon-Holder"
                  content={<SvgFilter className="dark-icon" />}
                />
                <div className="Radio-Selection">
                  <label
                    onClick={() => {
                      setMyCases(true);
                      updateUserSettings(true);
                    }}
                    htmlFor={`My-Case-True`}
                    className={`Text-Understated${myCases === true ? ' checked' : ''}`}
                  >
                    <input
                      type="radio"
                      id="true"
                      name={`My-Case-True`}
                      data-testid={`My-Case-True`}
                      value="true"
                    />
                    <div className="Radio-Icon-Holder">
                      {myCases === true ? (
                        <SvgGood className="Radio-Icon" />
                      ) : (
                        <SvgEmptyRadio
                          className="Radio-Icon"
                          data-testid={`True-Empty-False_SVG`}
                        />
                      )}
                    </div>
                    {locales.searchAndFilter.myCases}
                  </label>
                  <label
                    onClick={() => {
                      setMyCases(false);
                      updateUserSettings(false);
                    }}
                    htmlFor={`My-Case-False`}
                    className={`Text-Understated${myCases === false ? ' checked' : ''}`}
                  >
                    <input
                      type="radio"
                      id="false"
                      name={`My-Case-False`}
                      data-testid={`My-Case-False`}
                      value="false"
                      checked={myCases === false}
                    />
                    <div className="Radio-Icon-Holder">
                      {myCases !== false ? (
                        <SvgEmptyRadio className="Radio-Icon" />
                      ) : (
                        <SvgGood className="Radio-Icon" />
                      )}
                    </div>

                    {locales.searchAndFilter.allCases}
                  </label>
                </div>
              </div>
            )}
          </ListTable.SearchOptions>
        )}
        <ListTable.Headers>
          {locales.caseTable.headers.map((header: string, index: number) => {
            return <div key={`Header_${index}`}>{header}</div>;
          })}
        </ListTable.Headers>
      </ListTable>
      {UserCore.userIsCaseManagerOrHigher(userClaims!.user!) ? (
        <div className="Button-Container">
          <InformationButton
            buttonDetails={{
              itemKey: 'infoButtonCurrency',
              label: locales.createButton,
              mode: 'positive',
              clickEvent: () => navigate(`/cases/referral/create`),
            }}
          >
            {locales.createSummary}
          </InformationButton>
        </div>
      ) : null}
    </div>
  );
};
export default CaseIndexPage;
