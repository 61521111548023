import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/pricing/PricingActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import Enumerations, {
  getHeadersAsync,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import { ControlState } from '../../../sysObjects/common.types';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ListTableRow from '../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import PricingDefinition_Types from '../../../sysObjects/apiModels/PricingDefinition.types';
import PricingDetails_Types from '../../../systemComponents/targetedPageControls/pricing/index/Details/PricingDetails.types';
import StatusLabel from '../../../systemComponents/sharedControls/reusableBlocks/statusLabel/StatusLabel';
import PricingDetails from '../../../systemComponents/targetedPageControls/pricing/index/Details/PricingDetails';

import './PricingDefinitionDetailsIndex.css';
import locales from './locales/en-GB.json';
import { FunctionalResult } from '../../../sysObjects/FunctionalResult';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';

const PricingDefinition_Index: React.FC = () => {
  const intl = useIntl();
  // const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CommonPageContext);
  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [pricings, setPricings] = React.useState<
    PricingDetails_Types.pricingControlData_WithStates[]
  >([]);
  const currencies = Enumerations.getSupportedCurrencies(intl.locale).map(
    (currency) => {
      return { key: currency.code, value: currency.description };
    },
  );

  const saveRecord = async (
    data: PricingDefinition_Types.listItem,
  ): Promise<FunctionalResult<void>> => {
    return actions
      .savePricingDefinitionAsync(
        {
          name: data.name,
          description: data.description,
          currency: data.currency,
          status: 1,
        },
        await getHeadersAsync(userClaims, instance),
        data.id,
      )
      .then((result) => {
        if (result.isFailure) {
          showMessage(getServerErrors(locales, result.errorCode), 'negative');
          return FunctionalResult.Error('');
        }
        showMessage(
          `${data.name}: - ${locales.ApiResponses.saveSuccess}`,
          'positive',
          '/pricing',
        );

        setPricings((prevPricings) =>
          prevPricings.map((pricing) =>
            pricing.id === data.id
              ? { ...pricing, ...data, isReadOnly: true }
              : pricing,
          ),
        );
        return FunctionalResult.Success();
      });
  };

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    actions
      .findActivePricingDefinitionsAsync(
        null,
        await getHeadersAsync(userClaims, instance),
      )
      .then((result) => {
        if (result.isFailure || !result.result) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
          return;
        }

        setPricings(
          result.result!.map((pricing) => {
            return {
              id: pricing.id,
              name: pricing.name,
              description: pricing.description,
              currency: pricing.currency,
              status: pricing.status,
              isExpanded: false,
              isReadOnly: true,
              isButtonRowExpanded: false,
            };
          }),
        );
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }
    context?.handleCrumbUpdate(locales.breadcrumbs);
    loadDataAsync();
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={false}
        className="Pricing-List-Index"
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableName,
          emptyMessage: locales.emptyMessage,
        }}
        pagingDetails={{
          pageSizes: userSettings.pageSizes,
          currentPageSize: userSettings.startingPageSize,
        }}
        rows={pricings.map((pricing) => {
          return (
            <ListTableRow
              className="Pricing-List-Item"
              isExpanded={pricing.isExpanded}
              isButtonRowExpanded={pricing.isButtonRowExpanded}
              id={pricing.id}
              key={pricing.id}
              events={{
                OnExpandChange: (isExpanded: boolean, id: string) => {
                  setPricings((prevPricings) =>
                    prevPricings.map((pricing) =>
                      pricing.id === id
                        ? { ...pricing, isExpanded, isButtonRowExpanded: isExpanded === true ? false : pricing.isButtonRowExpanded }
                        : {
                            ...pricing,
                            isExpanded: false,
                            isButtonRowExpanded: false,
                          },
                    ),
                  );
                },
                OnButtonVisibilityChange: (
                  isButtonRowExpanded: boolean,
                  id: string,
                ) => {
                  setPricings((prevPricings) =>
                    prevPricings.map((pricing) =>
                      pricing.id === id
                        ? { ...pricing, isButtonRowExpanded }
                        : {
                            ...pricing,
                            isExpanded: false,
                            isButtonRowExpanded: false,
                          },
                    ),
                  );
                },
              }}
            >
              <ListTableRow.Expanded>
                <PricingDetails
                  pricingDefinition={pricing}
                  labels={{
                    actionLabel: locales.actionLabel,
                    description: locales.description,
                    name: locales.name,
                    required: locales.required,
                    requiredMessage: locales.requiredMessage,
                    currency: locales.currency,
                    defaultCurrency: locales.defaultCurrency,
                    editPriceButton: locales.editPriceButton,
                    editButton: locales.editButton,
                    cancelButton: locales.cancelButton,
                    saveButton: locales.saveButton,
                  }}
                  events={{
                    OnSave: saveRecord,
                  }}
                  supportedCurrencies={currencies}
                />
              </ListTableRow.Expanded>
              <ListTableRow.Collapsed>
                <div>{pricing.name}</div>
                <div>
                  <StatusLabel
                    renderAs="span"
                    label={pricing.currency}
                    status="neutral"
                  />
                </div>
                <div>{pricing.description}</div>
              </ListTableRow.Collapsed>
              <ListTableRow.ButtonRow
                isExpanded={pricing.isButtonRowExpanded}
                actionLabel={locales.actionLabel}
              >
                <ButtonBox
                  id={'RowButtons'}
                  buttons={[
                    {
                      id: `pricingActions_${pricing.id}`,
                      controlState: 'positive',
                      label: locales.editPriceButton,
                      onClick: () => navigate(`/pricing/edit/${pricing.id}`),
                    },
                    {
                      id: `pricingActions_${pricing.id}`,
                      controlState: 'positive',
                      label: locales.editButton,
                      onClick: () => {
                        setPricings((prevPricings) =>
                          prevPricings.map((LocalPricing) =>
                            LocalPricing.id === pricing.id
                              ? {
                                  ...LocalPricing,
                                  isReadOnly: false,
                                  isExpanded: true,
                                  isButtonRowExpanded: false,
                                }
                              : {
                                  ...LocalPricing,
                                  isReadOnly: false,
                                  isExpanded: false,
                                  isButtonRowExpanded: false,
                                },
                          ),
                        );
                      },
                    },
                  ]}
                  displayBorder={false}
                />
              </ListTableRow.ButtonRow>
            </ListTableRow>
          );
        })}
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular"></div>
          {locales.colNames.map((colName: string, index: number) => (
            <div
              className="Heading Text-Regular"
              key={`tableHeader_${colName}_${index}`}
            >
              {colName}
            </div>
          ))}
          <div className="Heading Text-Regular"></div>
        </ListTable.Headers>
      </ListTable>
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButtonBilling',
            label: locales.billingButton,
            mode: 'positive',
            clickEvent: () => navigate('/pricing/create'),
          }}
        >
          {locales.billingSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default PricingDefinition_Index;
