import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/userUtils/ExternalUserActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import ExternalUser_Types from '../../../sysObjects/apiModels/ExternalUser.types';
import { useMsal } from '@azure/msal-react';
import Enumerations, {
  getHeadersAsync,
  LocalEnumerations,
} from '../../../systemUtils/common/CommonHelpers';
import './ExternalUsersIndex.css';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonPageContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import ExternalUsers_IndexTypes from './ExternalUsersIndex.types';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ListTableRow from '../../../systemComponents/sharedControls/tables/listTable/listTableRow/ListTableRow';
import StatusBlock from '../../../systemComponents/sharedControls/reusableBlocks/statusBlock/StatusBlock';
import StatusLabel from '../../../systemComponents/sharedControls/reusableBlocks/statusLabel/StatusLabel';
import DateHelpers from '../../../systemUtils/common/DateHelpers';
import {
  SvgExternalUser,
  SvgOrganisation,
} from '../../../systemComponents/sharedControls/svg';

const ExternalUsers: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const context = React.useContext(CommonPageContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reload, setReload] = React.useState<boolean>(true);
  const [disabilities, setDisabilities] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [intUsers, setIntUsers] = React.useState<
    ExternalUsers_IndexTypes.rowData[]
  >([]);

  const showMessage = (
    message: string,
    state: ControlState,
    path?: string | null,
  ) => {
    context?.handleMessage({
      alertType: state,
      message: message,
    });
    if (path) {
      navigate(path);
    }
  };

  const loadDataAsync = async () => {
    actions
      .findUsersAsync(await getHeadersAsync(userClaims, instance), {
        status: [
          LocalEnumerations.UserStatuses.Active,
          LocalEnumerations.UserStatuses.Invited,
          LocalEnumerations.UserStatuses.Disabled,
        ],
      })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          showMessage(locales.ApiResponses.loadingFailed, 'negative', '/');
          return;
        }

        setIntUsers(
          result.result.map((usr: ExternalUser_Types.listItem) => {
            return {
              ...usr,
              isExpanded: false,
              buttonsExpanded: false,
              disabledChecked: false,
            };
          }),
        );
      });
  };

  React.useEffect(() => {
    setDisabilities(
      Enumerations.getDisabilities(intl.locale).map((item) => {
        return { key: item.key, value: item.value };
      }),
    );
  }, []);

  React.useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      navigate('/');
      return;
    }

    loadDataAsync();
    context?.handleCrumbUpdate(locales.breadcrumbs);
  }, [reload]);

  const DetermineUserButtons = (
    user: ExternalUser_Types.listItem,
    checked?: boolean,
  ): ButtonBoxTypes.Button[] => {
    let buttons: ButtonBoxTypes.Button[] = [];

    if (user.status !== LocalEnumerations.UserStatuses.Disabled) {
      buttons.push({
        id: `edit-${user.id}`,
        label: locales.statusButtons.edit,
        controlState: 'positive',
        onClick: () => {
          navigate(
            `/customers/accounts/edit/${locales.roles[user.role]}/${user.id}`,
          );
        },
      });
      if (
        user.role === LocalEnumerations.Roles.Customer &&
        UserCore.userIsCaseManagerOrHigher(userClaims!.user!)
      ) {
        buttons.push({
          id: `createProfile-${user.id}`,
          label:
            user.customerId == null
              ? locales.statusButtons.createProfile
              : locales.statusButtons.editProfile,
          controlState: 'positive',
          onClick: () => {
            const path = `/customers/accounts/Customer/${user.id}/profile`;
            navigate(
              user.customerId == null
                ? `${path}/create`
                : `${path}/${user.customerId}`,
            );
          },
        });
      }

      if (user.status === LocalEnumerations.UserStatuses.Invited) {
        buttons.push({
          id: `resendInvite-${user.id}`,
          controlState: 'positive',
          label: locales.statusButtons.resend,
          onClick: async () => {
            actions.sendRefreshToken(
              await getHeadersAsync(userClaims, instance),
              user.id,
            );
          },
        });
      }
    } else {
      buttons.push({
        id: `changeState-${user.id}`,
        controlState: 'positive',
        label: locales.statusButtons.enable,
        isChecked: checked,
        onClick: () => {
          updateStatus(user, LocalEnumerations.BasicEntityStatus.Active);
        },
      });
    }
    if (user.status !== LocalEnumerations.UserStatuses.Active) {
      return buttons;
    }

    buttons.push({
      id: `changeState-${user.id}`,
      controlState: 'warning',
      label: locales.statusButtons.disable,
      isChecked: checked,
      onClick: () => {
        setIntUsers((prevUsers) =>
          prevUsers.map((prevUser) =>
            prevUser.id === user.id
              ? {
                  ...prevUser,
                  disabledChecked: !prevUser.disabledChecked,
                }
              : prevUser,
          ),
        );
      },
    });
    return buttons;
  };

  const updateStatus = async (
    user: ExternalUser_Types.listItem,
    state: number,
  ) => {
    actions
      .setUserState(await getHeadersAsync(userClaims, instance), user.id, state)
      .then((result) => {
        if (result.isFailure) {
          showMessage(locales.ApiResponses.editState, 'negative');
          return;
        }
        setIsLoading(true);
        setReload(true);
      });
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : (
    <div className="Main-Form-Layout">
      <ListTable
        isLoading={false}
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableHeading,
          emptyMessage: locales.emptyMessage,
        }}
        rows={intUsers.map((usr) => {
          return (
            <ListTableRow
              events={{
                OnExpandChange: (isExpanded: boolean, id: string) => {
                  setIntUsers((prevUsers) =>
                    prevUsers.map((prevUser) =>
                      prevUser.id === id
                        ? {
                            ...prevUser,
                            isExpanded,
                            isButtonRowExpanded:
                              isExpanded === true
                                ? false
                                : prevUser.buttonsExpanded,
                            disabledChecked:
                              isExpanded === true
                                ? false
                                : prevUser.disabledChecked,
                          }
                        : {
                            ...prevUser,
                            isExpanded: false,
                            buttonsExpanded: false,
                            disabledChecked: false,
                          },
                    ),
                  );
                },
                OnButtonVisibilityChange: (
                  buttonsExpanded: boolean,
                  id: string,
                ) => {
                  setIntUsers((prevUsers) =>
                    prevUsers.map((prevUser) =>
                      prevUser.id === id
                        ? { ...prevUser, buttonsExpanded }
                        : {
                            ...prevUser,
                            isExpanded: false,
                            buttonsExpanded: false,
                            disabledChecked: false,
                          },
                    ),
                  );
                },
              }}
              id={usr.id}
              isButtonRowExpanded={usr.buttonsExpanded}
              isExpanded={usr.isExpanded}
              key={usr.id}
            >
              <ListTableRow.Collapsed>
                <div>
                  {usr.surname}, {usr.name}
                </div>
                <div>{usr.organisationName}</div>
                <div>{locales.roles[usr.role || 4]}</div>
                <div>
                  <StatusLabel
                    label={locales.statuses[usr.status || 0]}
                    renderAs="span"
                    key={`status-${usr.id}`}
                    status={
                      (usr.status || 0) === 0
                        ? 'neutral'
                        : (usr.status || 0) === 1
                          ? 'positive'
                          : 'negative'
                    }
                  />
                </div>
              </ListTableRow.Collapsed>
              <ListTableRow.Expanded>
                <div>{locales.name}</div>
                <div className="Icon-Col">
                  <StatusBlock
                    boxSize="small"
                    content={<SvgExternalUser className="icon" />}
                    boxState="positive"
                    id={`user-Icon=${usr.id}`}
                  />
                  <span>
                    {usr.surname}, {usr.name}
                  </span>
                </div>
                <div>{locales.organisation}</div>
                <div className="Icon-Col">
                  <StatusBlock
                    boxSize="small"
                    content={<SvgOrganisation className="icon" />}
                    boxState="positive"
                    id={`user-Icon=${usr.id}`}
                  />
                  <span>{usr.organisationName}</span>
                </div>
                {usr.role === 4 && (
                  <>
                    <div>{locales.disability}</div>
                    <div>
                      <ul>
                        {usr.disabilities.map((disability: number) => {
                          return (
                            <li key={disability}>
                              {
                                disabilities.find(
                                  (item) => item.key === disability,
                                )?.value
                              }
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
                <div>{locales.role}</div>
                <div>{locales.roles[usr.role || 4]}</div>
                <div>{locales.status}</div>
                <div>
                  <StatusLabel
                    label={locales.statuses[usr.status || 0]}
                    renderAs="span"
                    key={`status-${usr.id}`}
                    status={
                      (usr.status || 0) === 0
                        ? 'neutral'
                        : (usr.status || 0) === 1
                          ? 'positive'
                          : 'negative'
                    }
                  />
                </div>
                <div>{locales.dateOfBirth}</div>{' '}
                <div>
                  {DateHelpers.getLocalDateString(usr.birthDate, intl, 'MM')}
                </div>
                <div>{locales.email}</div>
                <div>{usr.primaryEmail}</div>
                <div>{locales.phone}</div>
                <div>{usr.primaryTelephoneNumber}</div>
                <div>{locales.actions}</div>
                <div className="Button-Box-Expand-Container">
                  <ButtonBox
                    id={`Button-Box-${usr.id}-expanded`}
                    displayBorder={true}
                    buttons={DetermineUserButtons(usr, usr.disabledChecked)}
                    className="Button-Box-Expand"
                  />
                  {usr.disabledChecked && (
                    <ButtonBox
                      id={`confirm-user-${usr.id}-render`}
                      key={`confirm-user-${usr.id}-render`}
                      className="Confirm-User-Buttons"
                      displayBorder={true}
                      buttons={[
                        {
                          id: `confirm-user-${usr.id}-yes`,
                          controlState: 'negative',
                          label: locales.statusButtons.confirm,
                          onClick: () => {
                            updateStatus(
                              usr,
                              LocalEnumerations.BasicEntityStatus.Deactivated,
                            );
                          },
                        },
                        {
                          id: `confirm-user-${usr.id}-no`,
                          controlState: 'positive',
                          label: locales.statusButtons.cancel,
                          onClick: () => {
                            setIntUsers((prevUsers) =>
                              prevUsers.map((prevUser) =>
                                prevUser.id === usr.id
                                  ? {
                                      ...prevUser,
                                      disabledChecked: false,
                                      buttonsExpanded: false,
                                    }
                                  : prevUser,
                              ),
                            );
                          },
                        },
                      ]}
                    />
                  )}
                </div>
              </ListTableRow.Expanded>
              <ListTableRow.ButtonRow
                isExpanded={usr.buttonsExpanded}
                actionLabel={locales.actions}
              >
                <ButtonBox
                  buttons={DetermineUserButtons(usr, usr.disabledChecked)}
                  displayBorder={true}
                  id={`user-${usr.id}`}
                />
                {usr.disabledChecked && (
                  <ButtonBox
                    id={`confirm-user-${usr.id}-render`}
                    key={`confirm-user-${usr.id}-render`}
                    className="Confirm-User-Buttons"
                    displayBorder={false}
                    buttons={[
                      {
                        id: `confirm-user-${usr.id}-yes`,
                        controlState: 'negative',
                        label: locales.statusButtons.confirm,
                        onClick: () => {
                          updateStatus(
                            usr,
                            LocalEnumerations.BasicEntityStatus.Deactivated,
                          );
                        },
                      },
                      {
                        id: `confirm-user-${usr.id}-no`,
                        controlState: 'positive',
                        label: locales.statusButtons.cancel,
                        onClick: () => {
                          setIntUsers((prevUsers) =>
                            prevUsers.map((prevUser) =>
                              prevUser.id === usr.id
                                ? {
                                    ...prevUser,
                                    disabledChecked: false,
                                    buttonsExpanded: false,
                                  }
                                : prevUser,
                            ),
                          );
                        },
                      },
                    ]}
                  />
                )}
              </ListTableRow.ButtonRow>
            </ListTableRow>
          );
        })}
        className="External-User-Table"
      >
        <ListTable.Headers>
          <div className="Heading Text-Regular"></div>
          {locales.colNames.map((colName: string, index: number) => (
            <div
              className="Heading Text-Regular"
              key={`tableHeader_${colName}_${index}`}
            >
              {colName}
            </div>
          ))}
          <div className="Heading Text-Regular"></div>
        </ListTable.Headers>
      </ListTable>

      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'info-button-customer',
            label: locales.createCustomer.button,
            mode: 'positive',
            clickEvent: () => navigate('/customers/accounts/create/customer'),
          }}
        >
          {locales.createCustomer.summary}
        </InformationButton>
        <InformationButton
          buttonDetails={{
            itemKey: 'info-button-referrer',
            label: locales.createReferrer.button,
            mode: 'positive',
            clickEvent: () => navigate('/customers/accounts/create/referrer'),
          }}
        >
          {locales.createReferrer.summary}
        </InformationButton>
      </div>
    </div>
  );
};

export default ExternalUsers;
