import React, { FC, useEffect, useState } from 'react';
import PriceEditingTypes from './PriceEditing.types';
import FormTextCapture from '../../../../sharedControls/formControls/formTextCapture/FormTextCapture';
import ButtonBox from '../../../../sharedControls/reusableBlocks/buttonBox/ButtonBox';
import { FormControlRef } from '../../../../sharedControls/formControls/formControlContainer/FormControlContainer.types';

const PriceEditing: FC<PriceEditingTypes.Props> = (props) => {
  const [price, setPrice] = useState<string>('');
  const ref = React.useRef<FormControlRef>(null);

  useEffect(() => {
    setPrice(props.price ? props.price.toString() : '');
  }, [props.price]);

  return (
    <div className='Price-Editing'>
      <FormTextCapture
        id={`price-${props.serviceId}`}
        decimalPlaces={2}
        requiredDetails={{
          formLabel: props.labels.requiredField,
          message: props.labels.requiredFieldMessage,
        }}
        displayMode="Column"
        fieldId="price"
        label={props.labels.controlLabel}
        value={price + ''}
        ref={ref}
        onChange={(evt) => {
          setPrice((evt.value || '').toString());
        }}
        errorMessage={props.labels.errorMessage}
        textInputType="price"
      />
      <div>{props.labels.actionLabel}</div>
      <ButtonBox
        id={`price-buttons-${props.serviceId}`}
        buttons={[
          {
            id: `save-${props.serviceId}`,
            label: props.labels.saveButton,
            controlState: 'positive',
            onClick: () => {
              if (!ref.current?.triggerValidation()) {
                return;
              }
              props.setPrice(Number(price));
            },
          },
          {
            id: `cancel-${props.serviceId}`,
            label: props.labels.cancelButton,
            controlState: 'negative',
            onClick: () => {
              setPrice(props.price ? props.price.toString() : '');
              props.cancelPrice();
            },
          },
        ]}
      />
    </div>
  );
};

export default PriceEditing;
